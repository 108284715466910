const initialState = {
    region: '',
    region_code: ''
};

const geolocationReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'geolocation/update':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default geolocationReducer;