import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container, Grid, Stack, Typography } from '@mui/material';

import PageHeader from '../../components/PageHeader';
import Loader from '../../components/Loader';
import DescriptionHolder from '../../components/DescriptionHolder';
import VideoWatched from '../../components/VideoWatched';

import { useUpdateDocumentMeta } from '../../hooks/useUpdateDocumentMeta';
import { useRetailerSettings } from '../../hooks/useRetailerSettings';
import { useLogPageView } from '../../hooks/useLogPageView';

import RetailerContext from '../../context';

function HowToScanPage() {
    const { t } = useTranslation(['translations', 'faqs']);
    const { retailer } = useContext(RetailerContext);

    const [loading, setLoading] = useState(false);
    const [playStatus, setPlayStatus] = useState('idle');
    const videoUrl = `${process.env.REACT_APP_API_URL}/assets/eaff47c0-7455-4db7-8c51-ee7fe794d78d`;

    const { retailerSettings, isLoading: retailerSettingsLoading } = useRetailerSettings();

    useUpdateDocumentMeta('How to Scan');
    useLogPageView(retailer.id);

    const faqs = [
        {
            question: <DescriptionHolder content={t('qrCodeApp.question', { ns: 'faqs' })} allowedTags={['a']} />,
            answer: <DescriptionHolder content={t('qrCodeApp.answer', { ns: 'faqs' })} allowedTags={['a']} />,
        },
    ];

    if (loading) return <Loader />;
    if (retailerSettingsLoading) return <Loader />;

    return (
        <>
            <Box className="beef-product-brand-page">
                <Container maxWidth disableGutters
                    sx={{ p: 0 }}
                >
                    <Box>
                        <PageHeader 
                            item={{
                                name: t('howToScan'),
                            }}
                            useAsLink={false}
                        />
                    </Box>
                </Container>

                <Container
                    disableGutters
                    sx={{
                        my: 5
                    }}
                >
                    <Container
                        className='padding-on-mobile'
                        sx={{
                            my: 6
                        }}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item
                                xs={12}
                            >
                                <Box>
                                    {
                                        !!!retailerSettings?.faqs &&
                                        <Box>
                                            {
                                                playStatus === 'played' &&
                                                <VideoWatched
                                                    played={playStatus === 'played'}
                                                    onEnded={() => setPlayStatus('played')}
                                                    achievementId={process.env.REACT_APP_SCAN_ACHIEVEMENT_ID}
                                                    collection={'how-to-scan'}
                                                    id={'HOW_TO_SCAN'}
                                                />
                                            }
                                            <Box
                                                className="recipe_video"
                                                sx={{
                                                    width: "100%",
                                                    height: '421px',
                                                    background: "#000",
                                                    alignContent: "center",
                                                    mb: 2,
                                                }}
                                            >
                                                <video
                                                    className="video-list_img"
                                                    controls
                                                    onEnded={() => setPlayStatus('played')}
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video>
                                            </Box>

                                            {
                                                faqs.map(faq => (
                                                    <Stack
                                                        key={faq.question}
                                                        spacing={2}
                                                        sx={{
                                                            mb: 5,
                                                            textAlign: 'justify',
                                                        }}
                                                    >
                                                        <Typography
                                                            className="faq-header"
                                                            variant='h3'
                                                            sx={{
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            {faq.question}
                                                        </Typography>
                                                        <Typography
                                                            variant='div'
                                                        >
                                                            {faq.answer}
                                                        </Typography>
                                                    </Stack>
                                                ))
                                            }
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
        </>
    )
}

export default HowToScanPage