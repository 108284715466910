import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Box, IconButton, Stack, Typography } from "@mui/material";

import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';

import Wysiwyg from "../../Wysiwyg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Close } from "@mui/icons-material";
import { getTranslation } from "../../../utils/translate";

export const DialogContestFooter = ({ handleClose, rules }) => {
    const { t } = useTranslation(['gamification', 'provinces']);
    const { code: languageCode } = useSelector(state => state.language);

    const contests = useSelector(state => state.contests);
    const contest = contests?.find(item => item?.id) || null;

    let finePrint;

    if (contest) {
        const translatedContest = getTranslation(contest, { key: 'languages_code', code: languageCode });

        finePrint = translatedContest?.fine_print || '';
    }

    return (
        <Box
            sx={{}}
        >
            <IconButton
                onClick={handleClose}
                sx={{
                    color: '#000',
                    position: 'absolute',
                    top: '0.15rem',
                    right: '0.25rem',
                    zIndex: '1',
                }}
            >
                <Close fontSize="interit" />
            </IconButton>
            {
                (rules == true) &&
                <>
                    <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                    >
                        <Box
                            sx={{}}
                        >
                            <img
                                className=""
                                src={CanadaBeefLogo}
                                alt={'Canada Beef Contest Logo'}
                                style={{
                                    height: '4rem',
                                    width: '4rem',
                                    objectFit: 'contain',
                                }}
                            />
                        </Box>

                        <Box>
                            <Typography
                                sx={{
                                    color: '#1A1511',
                                    fontWeight: '300',
                                    fontSize: '0.5rem',
                                    lineHeight: '0.6rem',
                                    textAlign: 'justify',
                                    '.wysiwyg p': {
                                        m: 0,
                                    },
                                }}
                            >
                                <Wysiwyg>
                                    {finePrint}
                                </Wysiwyg>
                            </Typography>
                        </Box>
                    </Stack>

                    <Box>
                        <Link
                            to={'/contest'}
                            target={'_blank'}
                        >
                            <Typography
                                sx={{
                                    color: '#1A1511',
                                    fontWeight: 'bold',
                                    fontSize: '0.75rem',
                                    textAlign: 'center',
                                    textTransform: 'lowercase',
                                    textDecoration: 'underline',
                                }}
                            >
                                <Wysiwyg>
                                    {t('enterContestRules')}
                                </Wysiwyg>
                            </Typography>
                        </Link>
                    </Box>
                </>
            }
        </Box>
    )
}