import i18n from '../i18n/config';

export const getTranslation = ($this = {}, language = { key: 'languages_code', code: 'en-US' }, translationsFieldName = 'translations' ) => {
  // return {} if translations field is absent
  if (!!!$this) return {};
  if (!!!$this[translationsFieldName]) return {};

  // filter desired translation
  let translations = $this[translationsFieldName].filter(translation => translation[language.key] === language.code);

  // if not found filter English translation
  if (!translations.length) {
    translations = $this[translationsFieldName].filter(translation => translation[language.key] === 'en-US');
  }

  // if not found return empty object
  if (!translations.length) {
    return {};
  } else {
    return translations[0];
  }
};

export const translateDifficulty = (difficulty) => {
  switch (difficulty) {
    case 0:
      return i18n.t('beginner');
    case 1:
      return i18n.t('beginner');
    case 2:
      return i18n.t('beginner');
    case 3:
      return i18n.t('intermediate');
    case 4:
      return i18n.t('intermediate');
    case 5:
      return i18n.t('expert');
    default:
      return '-';
  }
}