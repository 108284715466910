import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { shape, string } from 'prop-types';
import Wysiwyg from '../Wysiwyg';

import styles from './index.module.scss';

const Gallery = ({title, content, imageBlocks}) => {
  return (
    <Box>
      <Typography
        variant='h2'
      >
        <Wysiwyg>
          {title}
        </Wysiwyg>
      </Typography>

      <Typography
        variant='div'
      >
        <Wysiwyg>
          {content}
        </Wysiwyg>
      </Typography>

      <Box
        spacing={2}
        sx={{
          my: 3
        }}
      >
        {
          imageBlocks.map(imageBlock => (
            <Grid
              key={imageBlock.id}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
              >
                <Box
                  sx={{
                    py: 3,
                    px: 2
                  }}
                >
                  <img
                    className={styles.image}
                    src={imageBlock.imageUrl} 
                    alt={imageBlock.title}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={8}
              >
                <Box>
                  <Typography variant={'h4'}>
                    <strong>
                      {imageBlock.title}
                    </strong>
                  </Typography>
                  <Typography>
                    <Wysiwyg>
                      {imageBlock.content}
                    </Wysiwyg>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))
        }
      </Box>
    </Box>
  );
};

Gallery.propTypes = {
  title: string,
  content: string,
  imageBlocks: shape({
    imageUrl: string.isRequired,
    caption: string,
    title: string,
    content: string,
  })
};

export default Gallery;
