import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Menu from '../Menu';

import Logo from '../Logo';
import { IconButton, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Close, CropFree } from '@mui/icons-material';
import { useDefaultSettings } from '../../hooks/useDefaultSettings';
import RetailerContext from '../../context';
import { useTranslation } from 'react-i18next';

export default function SideBar() {
  const [open, setOpen] = useState(false);

  const { defaultSettings } = useDefaultSettings();
  const { retailer } = useContext(RetailerContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{
        position: 'relative',
      }}
      role="presentation"
    >
      <Box
        sx={{
          display: 'block',
          py: 2,
          textAlign: 'center',
        }}
      >
        <Logo
          style={{
            width: '200px',
            height: 'auto',
            maxHeight: '120px',
            objectFit: 'contain',
          }}
        />
      </Box>

      <>
        <Box
          sx={{
            py: 2,
            my: 2,
            backgroundColor: '#f3f4f5',
            borderTop: '1px solid #cacbcd',
            borderBottom: '1px solid #cacbcd',
          }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'space-evenly'}
          >
            <Link to='/favorites'>
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                spacing={1}
                sx={{
                  color: 'black',
                }}
              >
                <FavoriteBorderIcon
                  sx={{
                    fontSize: '2.5rem',
                    color: retailer.icon_color,
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: '0.8rem',
                    textAlign: 'center',
                  }}
                >
                  {t('favorites')}
                </Typography>
              </Stack>
            </Link>

            {
              !!retailer?.allow_scanning &&
              <Link to='/scanner'>
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  spacing={1}
                  sx={{
                    color: 'black',
                  }}
                >
                  <CropFree
                    sx={{
                      fontSize: '2.5rem',
                      color: retailer.icon_color,
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: '0.8rem',
                      textAlign: 'center',
                    }}
                  >
                    {t('scanBarcode')}
                  </Typography>
                </Stack>
              </Link>
            }
          </Stack>
        </Box>
      </>

      <>
        <Box
          sx={{
            mb: 1,
          }}
        >
          {
            (!!retailer?.main_menu || !!defaultSettings?.main_menu) &&
            <Menu
              id={retailer?.main_menu || defaultSettings?.main_menu}
              variant={'list'}
            />
          }
        </Box>

        <Menu
          id={retailer?.explore_more_menu || defaultSettings?.explore_more_menu}
          variant={'list'}
          title={t('home_title2')}
        />
      </>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'relative',
      }}
    >
      <MenuIcon
        onClick={toggleDrawer(true)}
        sx={{
          color: 'white',
          fontSize: 32
        }}
      />

      <Box
        className={`nav nav-black ${open && 'visible'}`}
      >
        <Box
          className={`nav nav-red ${open && 'visible'}`}
        >
          <Box
            className={`nav nav-white ${open && 'visible'}`}
          >
            <Box
              sx={{
                backgroundColor: 'white',
              }}
            >
              <Stack
                direction={'row'}
                justifyContent={'flex-end'}
              >
                <IconButton
                  aria-label="close"
                  onClick={toggleDrawer(false)}
                  sx={{
                    ml: 'auto',
                  }}
                >
                  <Close
                    sx={{
                      color: 'black',
                    }}
                  />
                </IconButton>
              </Stack>

              {DrawerList}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer> */}
    </Box>
  );
}