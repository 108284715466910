import { Container, Grid } from '@mui/material';
import { Box } from '@mui/system';

import useSWR from 'swr';

import notify from '../../components/Notification/helper';

import { fetcher } from '../../utils/fetcher';

import './index.scss';
import BeefProductBrand from '../../components/BeefProductBrand';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { useUpdateDocumentMeta } from '../../hooks/useUpdateDocumentMeta';

function Brands() {
  const { t } = useTranslation();

  useUpdateDocumentMeta(t('brands'));

  const fields = [
    '*',
    'translations.*',
  ];

  const { data:brands, isLoading, brandsError } = useSWR({ url: 'beef_product_branding', fields }, fetcher);

  return (
    <Box
      className={'author'}
    >

      <Container maxWidth disableGutters
        sx={{
          p: 0
        }}
      >
        <Box>
          <PageHeader
            item={{
              name: t('brands'),
            }}
            useAsLink={false}
          />
        </Box>
      </Container>
      
      <Container
        sx={{
          my: 5,
        }}
      >
        <Grid
          container
          spacing={2}
        >
            { brands?.map(beefProductBrand => (
              <Grid item
                key={beefProductBrand.id}
                xs={12}
              >
                <BeefProductBrand item={beefProductBrand} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Brands