import useSWR from "swr";
import { fetcher } from "../utils/fetcher"

export const useDefaultSettings = () => {    
    const fields = [
        '*',
    ];

    const { data:defaultSettings, error, isLoading } = useSWR({
        url: 'defaults',
        fields,
    }, fetcher);


    return {
        defaultSettings,
        isLoading,
        isError: error
    }
}