import { toast } from 'react-toastify';
import api from '../../api';

const notify = (type, message) => {
  const tostProp = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const payload = {
    status: type,
    message,
  };

  api.postLog(payload);
  // return type === 'default' ? toast(message, tostProp) : toast[type](message, tostProp);
};

export default notify;
