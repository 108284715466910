import moment from 'moment';

export const renderTime = (condition) => {
    if (condition < 60) {
        return moment().startOf('day').add(condition, 'minutes').format('mm[min]');
    }

    if (!(condition % 60)) {
        return moment().startOf('day').add(condition, 'minutes').format('H[hr]');
    }

    return moment().startOf('day').add(condition, 'minutes').format('H[hr] mm[min]');
};

export const calculateTotalTime = (...timesToAdd) => {
    const total = timesToAdd.reduce((partialSum, sum) => {
        return (sum + (Number.isNaN(partialSum) ? 0 : Number.parseInt(partialSum)))
    }, 0);

    return total;
}