const initialState = [];

const contestsReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'contests/update':
            return action.payload;
        default:
            return state;
    }
}

export default contestsReducer;