import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Steak from '../../shared/svg/Steak.svg';

import './DashboardCard.scss';
import { Box, Stack, Typography } from '@mui/material';

const DashboardCard = ({ item, external = true }) => {
  const [icon, setIcon] = useState(Steak);

  useEffect(() => {
    let iconUrl = (item.svg_icon && external) ?
      `${process.env.REACT_APP_API_URL}/assets/${item.svg_icon}?key=menu-card-image` :
      item.svg_icon;

    setIcon(iconUrl || Steak);
  }, [item]);
  
  return (
    <div
      key={`menu-${item.label}`}
      className=""
    >
      <Stack
        className='w-100'
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          className='w-100'
        >
          <Typography
            className="card_truncate"
            sx={{
              textAlign: 'center',
              my: 2,
              fontSize: {
                xs: '1.1rem',
                md: '1.2rem',
                lg: '1.3rem',
              }
            }}
            variant={'h6'}
          >
            {item.label}&nbsp;
          </Typography>
        </Box>
        <Box className='card_menu_tile_wrap w-100'>
          { item.externalLink ?
            <a className="card_menu-background card_menu_tile" href={item.goTo}>
              <Box
                sx={{
                  my: 5
                }}
              >
                <img
                  className={item.enlargeImg?"card_menu-icon card_menu-icon-large":"card_menu-icon"}
                  src={icon}
                  alt={item.label}
                  onError={() => { setIcon(Steak) }}
                />
              </Box>
            </a> :
            <Link className="card_menu-background card_menu_tile" to={
                typeof item.goTo === 'string' ?
                  {
                    pathname: item.goTo
                  } :
                  item.goTo
              }
            >
              <Box
                sx={{
                  my: 5
                }}
              >
                <img
                  className={item.enlargeImg?"card_menu-icon card_menu-icon-large":"card_menu-icon"}
                  src={icon}
                  alt={item.label}
                  onError={() => { setIcon(Steak) }}
                />
              </Box>
            </Link>
          }
        </Box>
      </Stack>
    </div>
  )
}

DashboardCard.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    goTo: PropTypes.string,
    svg_icon: PropTypes.string,
    enlargeImg: PropTypes.bool
  }).isRequired,
  isEmpty: PropTypes.bool,
};

export default DashboardCard;
