import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getTranslation } from "../utils/translate";
import { useTranslation } from "react-i18next";
import { useRetailerSettings } from "./useRetailerSettings";

export const useUpdateDocumentMeta = (title='') => {
    const { code:languageCode } = useSelector(state => state.language);
    const { retailerSettings } = useRetailerSettings();
    const { t } = useTranslation();

    useEffect(async () => {
        if (title) {
            document.title = title;
        } else {
            document.title = getTranslation(retailerSettings, { key: 'languages_code', code: languageCode }).title || t('title');
        }

        return () => {
            document.title = t('title');
        }
    }, [retailerSettings, title]);
}