import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Box, Dialog, DialogContent, IconButton, Slide, Stack, Typography } from "@mui/material";

import RetailerContext from "../../../context";

import Stripes from '../../../shared/img/stripes.png';

import SocialMediaIcons from "../SocialMediaIcons";
import Wysiwyg from "../../Wysiwyg";
import { useFetchPoints } from "../../../hooks/useFetchPoints";
import { getTranslation } from "../../../utils/translate";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const SocialShareDialogoxBox = (props) => {
    const { t } = useTranslation(['gamification']);

    const { open = false, onClose } = props;
    const { totalPoints: points } = useFetchPoints();
    const { retailer } = useContext(RetailerContext);
    const { code: languageCode } = useSelector(state => state.language);
    
    let translatedRetailer = {};
    if (retailer) {
        translatedRetailer = getTranslation(retailer, { key: 'languages_code', code: languageCode });
    }

    const shareMessage = `I just earned ${points} star${points === 1 ? '' : 's'} on ${translatedRetailer.retailer_name}`;

    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Dialog
            className="page-header"
            open={open}
            onClose={() => handleClose('promotion')}
            maxWidth={'xs'}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    borderRadius: '1rem',
                }
            }}
        >
            <DialogContent
                sx={{
                    pt: 4,
                    overflowX: 'hidden',
                }}
            >
                <IconButton
                    onClick={() => handleClose('promotion')}
                    sx={{
                        color: '#000',
                        position: 'absolute',
                        top: '0.15rem',
                        right: '0.25rem',
                        zIndex: '1',
                    }}
                >
                    <Close fontSize="interit" />
                </IconButton>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        position: 'relative',
                        mb: 2,
                        mx: '-1.5rem',
                        px: '1.5rem',
                    }}
                >
                    <img
                        src={Stripes}
                        alt={'Stripe Art'}
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            right: 0,
                            left: 0,
                        }}
                    />

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '2rem',
                            fontWeight: 'bolder',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            py: 3,
                        }}
                    >
                        {t('shareYourProgressTitle')}
                    </Typography>
                </Box>

                <Typography
                    sx={{
                        color: '#000',
                        fontWeight: 300,
                        fontSize: '1.1rem',
                        textAlign: 'center',
                        '& p': {
                            m: 0,
                        }
                    }}
                >
                    <Wysiwyg>
                        {t('shareYourProgressMessage')}
                    </Wysiwyg>
                </Typography>

                <Stack
                    sx={{
                        mt: 2,
                    }}
                >
                    <SocialMediaIcons
                        shareMessage={shareMessage}
                        sx={{
                            fontSize: '2rem',
                        }}
                    />
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const DialogSocialShare = ({ defaultOpen = false, onClose }) => {
    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box
            className='no-print'
        >
            <SocialShareDialogoxBox
                open={defaultOpen}
                onClose={handleClose}
            />
        </Box>
    )
}