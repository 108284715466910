import { useState, Fragment } from 'react';

import { ReactComponent as RadioButtonOff } from '../../../shared/svg/radio-button-off.svg';
import { ReactComponent as RadioButtonOn } from '../../../shared/svg/radio-button-on.svg';

import { ReactComponent as CheckboxChecked } from '../../../shared/svg/checkbox-checked.svg';
import { ReactComponent as CheckboxUnchecked } from '../../../shared/svg/checkbox-unchecked.svg';

import {
  Box,
  Typography,
  Stack,
} from '@mui/material';

import './index.scss';
import Wysiwyg from '../../Wysiwyg';

export default function CheckListItem({ item = {}, checked = false, checkbox }) {
  const [isChecked, setIsChecked] = useState(checked);

  const toggleRadioButton = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="recipe-accordion-checklist-item">
      <Stack
        direction={'row'}
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        mb={1}
        onClick={toggleRadioButton}
      >
        <Box>
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              backgroundColor: 'rgba(216,216,216,0.41)',
              mr: 1,
              px: 1,
              height: '100%'
            }}
          >
            {
              !!checkbox ?
              <Fragment>
                {
                  isChecked ?
                    <CheckboxChecked className='recipe_accordion_checkbox recipe_accordion_checkbox_checked' /> :
                    <CheckboxUnchecked className='recipe_accordion_checkbox' />
                }
              </Fragment> :
              <Fragment>
                {
                  isChecked ?
                    <RadioButtonOn className='recipe_accordion_radio_button recipe_accordion_radio_button_on' /> :
                    <RadioButtonOff className='recipe_accordion_radio_button' />
                }
              </Fragment>
            }
          </Stack>
        </Box>
        <Stack
          sx={{ my: 1 }}
        >
          <Typography
            className={isChecked ? 'strikethrough' : ''}
            sx={{ 
              fontSize: '16px',
              fontWeight: 300,
              py: 0,
            }}
            component={'div'}
          >
            <Wysiwyg>
              {item.ingredient || item.text || item.name}
            </Wysiwyg>
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
}