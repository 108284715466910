const initialState = {
    region: '',
    region_code: ''
};

const locationReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'province/update':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default locationReducer;