import { Box } from "@mui/material";
import { useContext } from "react";
import RetailerContext from "../../../context";
import BeefProductTile from "../../BeefProductTile";
import { getTranslation } from "../../../utils/translate";
import { useSelector } from "react-redux";

function BeefCuts({beefCuts, sortingFunction}) {
    const { retailer } = useContext(RetailerContext);
    const { code: languageCode } = useSelector(state => state.language);

    const filteredBeefCuts = beefCuts
        .filter(beefCut => beefCut.status === 'published')
        .filter(beefCut => {
            const retailerIds = beefCut.retailers.map(retailerJunction => retailerJunction.retailer_id);
            return retailerIds.includes(retailer.id)
        })
        .sort(sortingFunction || function (a, b) {
            const c = a.retailers.filter(retailer => retailer.retailer_id === retailer.id)?.sort;
            const d = b.retailers.filter(retailer => retailer.retailer_id === retailer.id)?.sort;

            return c - d;
        });
        
    return (
        <Box
            fixed
            className='padding-on-mobile'
            sx={{}}
        >
            {
                filteredBeefCuts?.map((beefCut) => (
                    <BeefProductTile
                        key={beefCut.id}
                        item={beefCut}
                    />
                ))
            }
        </Box>
    )
}

export default BeefCuts