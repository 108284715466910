
const headerReducer = (state = { name: '', svg_icon: '' }, action) => {
    switch(action.type) {
        case 'header/update':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default headerReducer;