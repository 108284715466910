import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Container, Grid, IconButton, Typography } from "@mui/material";

import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';
import ContestArt from '../../../shared/img/Bitmap.png';

import Wysiwyg from "../../Wysiwyg";

import styles from './styles.module.scss';
import { Close } from "@mui/icons-material";
import api from "../../../api";
import { useGetShortCode } from "../../../hooks/useGetShortCode";
import { getTranslation } from "../../../utils/translate";
import { useSelector } from "react-redux";

export const DialogContestNag = ({ defaultOpen, onClose }) => {
    const { t } = useTranslation(['gamification']);
    const [open, setOpen] = useState(defaultOpen);
    const shortCode = useGetShortCode();
    const [enteredContent, setEnteredContent] = useState(false);

    const { code: languageCode } = useSelector(state => state.language);

    const contests = useSelector(state => state.contests);

    const contest = contests?.find(item => item?.id) || null;

    let tagline;
    let subtitle;
    let featuredImage;

    if (contest) {
        const translatedContest = getTranslation(contest, { key: 'languages_code', code: languageCode });

        featuredImage = `${process.env.REACT_APP_API_URL}/assets/${translatedContest?.featured_image}?key=contest-feature-image` || ContestArt;
        tagline = translatedContest?.tagline || t('enterContestInfoSubtitle');
        subtitle = translatedContest?.subheading || t('enterContestDescription');
    }
    
    const handleOnClose = (componentToOpen = null) => {
        setOpen(false);

        if (onClose) {
            onClose(componentToOpen);
        }
    }

    const getContestJunctionItems = async () => {
        try {
            const contestJunctionItems = await api.getJunctionItems('contests_short_codes', {
                filter: {
                    _and: [
                        {
                            contests_id: {
                                _eq: '9ec45a0f-eacc-4d9d-acd9-303be3893629',
                            },
                        },
                        {
                            short_codes_id: {
                                _eq: shortCode
                            },
                        }
                    ]
                }
            });

            if (contestJunctionItems?.length) {
                setEnteredContent(true);
            } else {
                setEnteredContent(false);
            }
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }

    useEffect(async () => {
        if (defaultOpen) {
            getContestJunctionItems();
        }
    }, [defaultOpen]);

    if (!defaultOpen) {
        return null;
    }

    return (
        <Box
            className={`no-print ${styles.contest_nag}`}
        >
            <Box
                className={styles.nag_container}
                sx={{
                    position: 'relative',
                }}
            >
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.preventDefault();
                        handleOnClose('promotion');
                    }}
                    sx={{
                        color: '#000',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        zIndex: '1',
                        fontSize: '1.5rem',
                    }}
                >
                    <Close fontSize="inherit" />
                </IconButton>

                <Box
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={() => handleOnClose(enteredContent ? 'my-achievements' : 'contest-info')}
                >
                    <Container
                        sx={{
                            px: {
                                xs: 0,
                                md: 'unset',
                            },
                        }}
                    >
                        <Grid container
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            rowSpacing={1}
                        >
                            <Grid item
                                xs={8}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: {
                                            xs: '1rem',
                                            s: '1.5rem',
                                            md: '1.5rem',
                                        },
                                        fontWeight: 'bolder',
                                        '& strong': {
                                            color: '#B92534',
                                        },
                                        lineHeight: '1',
                                        my: 1,
                                        px: {
                                            xs: 2,
                                            md: 0,
                                        },
                                    }}
                                >
                                    <Wysiwyg>
                                        {tagline}
                                    </Wysiwyg>
                                </Typography>
                            </Grid>

                            <Grid item
                                xs={4}
                            >
                                <Box
                                    sx={{
                                        height: '12rem',
                                        position: 'relative',
                                    }}
                                >
                                    <img
                                        className=""
                                        src={featuredImage}
                                        alt={'Canadian Beef Contest Art'}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Box>
    )
}