import React, {
  lazy,
  Suspense,
  useState,
} from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import RetailerContext from '../context';

// pages
const DashBoard = lazy(() => import('../components/DashBoard'));
const ScannerPage = lazy(() => import('../pages/Scanner'));
const ListProducts = lazy(() => import('../components/ListProducts'));
const BarCodeNotFound = lazy(() => import('../components/BarCodeNotFound'));
const Coupons = lazy(() => import('../components/Coupons'));
const PromotedProducts = lazy(() => import('../components/PromotedProducts'));
const CookingMethodsList = lazy(() => import('../components/CookingMethodsList'));
const CategoryList = lazy(() => import('../components/CategoryList'));
const CategoryListB = lazy(() => import('../components/CategoryListB'));
const RecipesList = lazy(() => import('../pages/RecipesList'));
const Video = lazy(() => import('../pages/Video'));
const Recipe = lazy(() => import('../components/Recipe'));
const QRCodeShimmer = lazy(() => import('../components/QRCodeShimmer'));
const ListVideo = lazy(() => import('../pages/ListVideo'));
const BeefProductBrandPage = lazy(() => import('../components/BeefProductBrandPage'));
const FaqsPage = lazy(() => import('../pages/FaqsPage'));
const ContestPage = lazy(() => import('../pages/ContestPage'));
const CookingMethod = lazy(() => import('../pages/CookingMethod'));
const ListNutrientLabels = lazy(() => import('../components/ListNutrientLabels'));
const TermsOfServicePage = lazy(() => import('../components/TermsOfServicePage'));

import Footer from '../components/Footer';
import Contact from '../components/Contact';
import Loader from '../components/Loader';
import Header from '../components/Header';
import BackButton from '../components/BackButton';

import { useLocalStorage } from '../hooks/useLocalStorage';
import CollectionPage from '../routes/collection-page';
import Author from '../pages/Author';
import Brands from '../pages/Brands';
import BeefPrimal from '../pages/BeefPrimal';
import BeefSubprimal from '../pages/BeefSubprimal';
import BeefRetail from '../pages/BeefRetail';
import Article from '../pages/Article';
import Articles from '../pages/Articles';
import ScrollToTop from '../components/shared/ScrollToTop';
import Error404 from '../pages/Error404';
import PageLayout from '../layouts/PageLayout';
import HowToScanPage from '../pages/HowToScan';
import FindABeefCut from '../pages/FindABeefCut';
import Favorites from '../pages/Favorites';

const AppRouter = () => {
  // override default theme and use user-defined theme
  const [themeOverride, setThemeOverride] = useState(
    createTheme({})
  );

  const [subDomain,] = useState(process.env.NODE_ENV === 'production' ?
    window.location.hostname :
    process.env.REACT_APP_BASE_URL
  );

  const defaultRetailer = {
    body_font: "Oswald",
    brand_color: "#C31E39",
    brand_color_2: "#AAAAAA",
    button_background_color: "#C31E39",
    button_font: "Oswald",
    description: "Canada: The world’s perfect place for raising beef. Join the Conversation, exciting things are happening at Canada Beef",
    header_font: "Oswald",
    icon_color: "#ffffff",
    id: "b4405e7d-44f9-42b7-b13b-75e8743aa064",
    ip_address: "XXX.XXX.XXX.XXX",
    favicon: {
      id: "cd590702-402c-4736-88de-099b788e89d4"
    },
    logo: {
      id: "cd590702-402c-4736-88de-099b788e89d4"
    },
    meta: "Canada Beef | Leading Supplier",
    retailer_name: "Canada Beef",
    scripts: [],
    site_background_color: "#ffffff",
    subdomain: "staging.cdnbeef.ca",
    store_url: "canadabeef.ca",
    title: "We put the best of Canada in our Beef - Canadian Beef | Canada Beef",
    translations: [],
  };

  const [retailer, setRetailer] = useLocalStorage(subDomain, {
    ...defaultRetailer,
    id: null
  });

  // TODO remove context
  const value = { retailer, defaultRetailer, setRetailer };

  const HomeLayout = (props) => {
    return (
      <>
        <Header />
        <BackButton showBackButton={false} />
        {props.children}
        <Footer />
      </>
    )
  }

  return (
    <ThemeProvider theme={themeOverride}>
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <RetailerContext.Provider value={value}>
            <Switch>
              <Route exact path="/">
                <HomeLayout>
                  <DashBoard />
                </HomeLayout>
              </Route>
              <Route
                path="/articles/category/:categoryId"
              >
                <PageLayout>
                  <Articles />
                </PageLayout>
              </Route>
              <Route
                path="/articles/author/:authorId"
              >
                <PageLayout>
                  <Articles />
                </PageLayout>
              </Route>
              <Route
                path="/favorites/"
              >
                <PageLayout>
                  <Favorites />
                </PageLayout>
              </Route>
              <Route
                path="/articles/"
              >
                <PageLayout>
                  <Articles />
                </PageLayout>
              </Route>
              <Route
                path="/article/:id?"
              >
                <PageLayout>
                  <Article />
                </PageLayout>
              </Route>
              <Route path="/list-products/:id?">
                <PageLayout>
                  <ListProducts />
                </PageLayout>
              </Route>
              <Route path="/recipe-list/:id/:retailerId">
                <PageLayout>
                  <RecipesList />
                </PageLayout>
              </Route>
              <Route path="/recipe-list">
                <PageLayout>
                  <RecipesList />
                </PageLayout>
              </Route>
              <Route path="/recipe/:id">
                <PageLayout
                  showLogo={false}
                >
                  <Recipe />
                </PageLayout>
              </Route>
              <Route path="/author/:authorId">
                <PageLayout>
                  <Author />
                </PageLayout>
              </Route>
              <Route path="/worldcuisine">
                <Redirect to="/collections/6c2309d2-8459-4c10-8bf0-c43012b729f3" />
              </Route>
              <Route path="/collections/:collectionId">
                <PageLayout>
                  <CollectionPage />
                </PageLayout>
              </Route>
              <Route path="/beef/primal/:beefProductId">
                <PageLayout>
                  <BeefPrimal />
                </PageLayout>
              </Route>
              <Route path="/beef/retail/:beefProductId">
                <PageLayout>
                  <BeefRetail />
                </PageLayout>
              </Route>
              <Route path="/beef/subprimal/:beefProductId">
                <PageLayout>
                  <BeefSubprimal />
                </PageLayout>
              </Route>
              <Route path="/beef/:beefProductId">
                <PageLayout>
                  <BeefPrimal />
                </PageLayout>
              </Route>
              <Route path="/beef_product/:beefProductId">
                <PageLayout>
                  <BeefPrimal />
                </PageLayout>
              </Route>
              <Route path="/cooking-methods">
                <PageLayout>
                  <CookingMethodsList />
                </PageLayout>
              </Route>
              <Route path="/cooking-method">
                <PageLayout>
                  <CookingMethod />
                </PageLayout>
              </Route>
              <Route path="/video-classroom/:id">
                <PageLayout>
                  <Video />
                </PageLayout>
              </Route>
              <Route path="/qr/:id">
                <PageLayout>
                  <QRCodeShimmer />
                </PageLayout>
              </Route>
              <Route path="/nutrient-labels">
                <PageLayout>
                  <ListNutrientLabels />
                </PageLayout>
              </Route>
              <Route path="/faqs">
                <PageLayout>
                  <FaqsPage />
                </PageLayout>
              </Route>
              <Route path="/rules">
                <PageLayout>
                  <ContestPage />
                </PageLayout>
              </Route>
              <Route path="/contest">
                <PageLayout>
                  <ContestPage />
                </PageLayout>
              </Route>
              <Route path="/find-a-beef-cut">
                <PageLayout>
                  <FindABeefCut />
                </PageLayout>
              </Route>
              <Route path="/how-to-scan">
                <PageLayout>
                  <HowToScanPage />
                </PageLayout>
              </Route>
              <Route path="/brands">
                <PageLayout>
                  <Brands />
                </PageLayout>
              </Route>
              <Route path="/terms-of-service">
                <PageLayout>
                  <TermsOfServicePage />
                </PageLayout>
              </Route>
              <Route path="/contact">
                <PageLayout>
                  <Contact />
                </PageLayout>
              </Route>
              <Route path="/beef-product-brand/:id">
                <PageLayout>
                  <BeefProductBrandPage />
                </PageLayout>
              </Route>
              <Route path="/category-list">
                <PageLayout>
                  <CategoryList />
                </PageLayout>
              </Route>
              <Route path="/category-list-b">
                <CategoryListB />
              </Route>
              <Route exact path="/scanner">
                <ScannerPage />
              </Route>
              <Route exact path="/products">
                <PageLayout>
                  <BarCodeNotFound />
                </PageLayout>
              </Route>
              <Route exact path="/coupons">
                <PageLayout>
                  <Coupons />
                </PageLayout>
              </Route>
              <Route exact path="/promo-products">
                <PageLayout>
                  <PromotedProducts />
                </PageLayout>
              </Route>
              <Route exact path="/video-classroom">
                <PageLayout>
                  <ListVideo />
                </PageLayout>
              </Route>
              <Route path="*" element={<Error404 />} />
            </Switch>
          </RetailerContext.Provider>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
