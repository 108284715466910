const search = (state = {}, action) => {
    switch(action.type) {
        case 'search/clear':
            return {};
        case 'search/update':
            return {
                ...state,
                query: action.payload
            };
        default: 
            return state;
    }
};

export default search;