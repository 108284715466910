import useSWR from "swr";
import { fetcher } from "../utils/fetcher"

export const useRetailerSettings = () => {
    const subdomain = process.env.NODE_ENV === 'production' ?
        window.location.hostname :
        process.env.REACT_APP_BASE_URL
    ;

    const deep = {
        regions: {
            _filter: {
                status: {
                    _eq: 'published'
                }
            }
        },
        redirect_sites: {
            _filter: {
                status: {
                    _eq: 'published'
                }
            }
        }
    };

    const filter = {
        '_and': ([
            {
                domains: {
                    domain: {
                        _eq: subdomain
                    }
                }
            },
        ])
    };
    
    const fields = [
        '*',
        'favicon.*',
        'logo.*',
        'redirect_sites.sort',
        'redirect_sites.status',
        'redirect_sites.url',
        'redirect_sites.provinces',
        'translations.*',
    ];

    const { data, error, isLoading } = useSWR({
        url: 'retailer',
        fields,
        filter,
        deep
    }, fetcher);

    const [retailerSettings] = data || [];

    return {
        retailerSettings,
        isLoading,
        isError: error
    }
}