
import { useTranslation } from 'react-i18next';

import { Box, Container, Stack, Typography } from '@mui/material';

import PageHeader from '../../components/PageHeader';
import { useUpdateDocumentMeta } from '../../hooks/useUpdateDocumentMeta';

function Error404() {
    const { t } = useTranslation();

    useUpdateDocumentMeta(t('error404'));

    return (
        <>
            <Box className="beef-product-brand-page">
                <Container maxWidth disableGutters
                    sx={{ p: 0 }}
                >
                    <Box>
                        <PageHeader 
                            item={{
                                name: t('faqs', { ns: 'faqs'}),
                            }}
                            useAsLink={false}
                        />
                    </Box>
                </Container>

                <Container
                    disableGutters
                    sx={{
                        my: 5
                    }}
                >
                    <Container
                        className='padding-on-mobile'
                        sx={{
                            my: 6
                        }}
                    >
                    </Container>
                </Container>
            </Box>
        </>
    )
}

export default Error404