const language = (state = {}, action) => {
    switch(action.type) {
        case 'language/update':
            return {
                ...state,
                ...action.payload
            };
        default: 
            return state;
    }
};

export default language;