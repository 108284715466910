import { Link } from 'react-router-dom';

import LinkMUI from '@mui/material/Link';
import BreadcrumbsMUI from '@mui/material/Breadcrumbs';
import { Box, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';
import { array, arrayOf, bool, element, oneOf, oneOfType, shape, string } from 'prop-types';

function Breadcrumbs({ home, links}) {
  return (
    <Box
        sx={{}}
    >
        <BreadcrumbsMUI aria-label="breadcrumb">
            {
                !!home &&
                <Link
                    to={'/'}
                >
                    <LinkMUI
                        underline="hover"
                        color="black"
                        component={'div'}
                    >
                        <Home />
                    </LinkMUI>
                </Link>
            }

            {
                links.map(link => (
                    <Link
                        key={link.url}
                        to={link.url}
                    >
                        <LinkMUI
                            underline="hover"
                            color="#3a3a3a"
                            component={'div'}
                        >
                            {link.content}
                        </LinkMUI>
                    </Link>
                ))
            }
        </BreadcrumbsMUI>
    </Box>
  )
}

Breadcrumbs.propTypes = {
    home: bool,
    links: arrayOf(
        shape({
            url: string,
            content: oneOfType([
                string,
                element
            ]).isRequired
        })
    )
}

export default Breadcrumbs