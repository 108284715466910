
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useSWR from 'swr';

import { useTranslation } from 'react-i18next';

import API from '../../api';

import RetailerContext from '../../context';

import {
  Box,
} from '@mui/material';

import { FavoriteBorder, PrintOutlined } from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { useLogPageView } from '../../hooks/useLogPageView';
import { getTranslation, translateDifficulty } from '../../utils/translate';
import { useUpdateDocumentMeta } from '../../hooks/useUpdateDocumentMeta';

import notify from '../Notification/helper';
import Loader from '../Loader';

import { pushToGoogleAnalytics } from '../../utils/google-analytics';
import { calculateTotalTime, renderTime } from '../../utils/time';
import api from '../../api';
import { useGetShortCode } from '../../hooks/useGetShortCode';
import { fetcher } from '../../utils/fetcher';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Favorite = () => {
  const { t } = useTranslation();
  const language = useSelector(state => state.language);
  const shortCode = useGetShortCode();

  const location = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isOptimisticFavorited, setIsOptimisticFavorited] = useState(null);

  let query = useQuery();
  const beefProductId = query.get('beef_cut') || '';

  // check if user favorited the item
  const { data, isLoading: isFavoriteStatusLoading } = useSWR({
    url: `short_codes_favorites`,
    fields: '*,*.*',
    filter: {
      _and: [
        { short_codes_id: { _eq: shortCode } },
        { item: { _eq: beefProductId } },
        { collection: { _eq: 'beef_product' } }
      ]
    }
  }, fetcher);

  const favouriteItem = async () => {
    const payload = {
      favorites: {
        create: [
          {
            short_codes_id: shortCode,
            collection: "beef_product",
            item: {
              id: beefProductId,
            }
          }
        ],
        update: [],
        delete: [],
      }
    };

    setIsOptimisticFavorited(true);
    await api.updateItem(`short_codes/${shortCode}`, payload);
  }

  const unfavouriteItem = async () => {
    const query = {
      "filter": {
        _and: [
          { short_codes_id: { _eq: shortCode } },
          { item: { _eq: beefProductId } },
          { collection: { _eq: 'beef_product' } }
        ]
      }
    };

    setIsOptimisticFavorited(false);
    await api.deleteItem(`short_codes_favorites`, { query });
  }

  const showFavoriteIcon = () => {
    const favouriteIcon = (
      <Box
        sx={{
          cursor: 'pointer',
          ml: 1,
        }}
      >
        <a
          onClick={() => {
            pushToGoogleAnalytics({
              event: 'beefCutFavorite',
              text: ''
            });

            favouriteItem();
          }}
        >
          <FavoriteBorder sx={{ ml: 'auto', color: 'red', fontSize: 32 }} />
        </a>
      </Box>
    );

    const unfavouriteIcon = (
      <Box
        sx={{
          cursor: 'pointer',
          ml: 1,
        }}
      >
        <a
          onClick={() => {
            pushToGoogleAnalytics({
              event: 'beefCutFavorite',
              text: ''
            });

            unfavouriteItem();
          }}
        >
          <FavoriteIcon sx={{ ml: 'auto', color: 'red', fontSize: 32 }} />
        </a>
      </Box>
    );

    if (data?.length) {
      if (isOptimisticFavorited === false) {
        return favouriteIcon;
      } else {
        return unfavouriteIcon;
      }
    } else {
      if (isOptimisticFavorited === true) {
        return unfavouriteIcon;
      } else {
        return favouriteIcon;
      }
    }
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box
      className='no-print'
    >
      {showFavoriteIcon()}
    </Box>
  );
};

export default Favorite;
