import React from 'react'
import { useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';

import constants from '../../../utils/constants';

import './index.scss';

function Videos({ videos }) {
  const language = useSelector(state => state.language);

  if (!videos?.length) return null;

  const parsedVideos = [];

  videos?.forEach(video => {
    video?.vimeo?.forEach(vimeo => {
      const embedId = vimeo.vimeo_id.replace('https://vimeo.com/', '');

      parsedVideos.push({
        embedId,
        embedUrl: `${constants.VIMEO_PLAYER_URL}/${embedId}`,
        videoType: 'vimeo',
      })
    });

    video?.youtube?.forEach(youtube => {

      parsedVideos.push({
        embedId: youtube.youtube_id,
        embedUrl: `${constants.YOUTUBE_PLAYER_URL}/${youtube.youtube_id}`,
        videoType: 'youtube',
      })
    })
  });

  console.log('parsedVideos: ', parsedVideos);

  return (
    <Grid container
      spacing={2}
    >
      {
        parsedVideos?.map(item => (
          <Grid item
            xs={12}
            md={6}
            lg={parsedVideos.length > 1 ? 6 : 12}
            key={item.embedId}
          >
            <Box
              className="collection_video"
              dataSrc={item.embedUrl}
              sx={{
                width: "100%",
                background: "#222",
                alignContent: "center"
              }}
            >
              <iframe
                src={item.embedUrl}
                className="video-list_img"
                title="Recipe Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          </Grid>
        ))
      }
    </Grid>
  )
}

export default Videos