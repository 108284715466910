import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import api from "../../api";

import DescriptionHolder from "../../components/DescriptionHolder";
import Loader from "../../components/Loader";
import notify from "../../components/Notification/helper";
import PageHeader from "../../components/PageHeader";

import RetailerContext from "../../context";

import { getTranslation } from "../../utils/translate";
import { getBeefProductOverride } from '../../utils/overrides';

import './index.scss';
import AccordionSection from "../../components/AccordionSection";
import Collection from "../../components/Collection";
import BeefCuts from "../../components/Collection/BeefCuts";

function BeefRetail() {
  const language = useSelector(state => state.language);
  const { retailer } = useContext(RetailerContext);

  const { beefProductId } = useParams();
  const [beefProduct, setBeefProduct] = useState({});
  const [loading, setLoading] = useState(false);

  const apiFieldsToFetch = [
    'id',
    'icon',
    'translations.*'
  ];

  const getBeefProduct = async (beefProductId) => {

    const fields = [
      'beef_product_brandings.beef_product_branding_id.brand_color',
      'beef_product_brandings.beef_product_branding_id.id',
      'beef_product_brandings.beef_product_branding_id.image',
      'beef_product_brandings.beef_product_branding_id.logo',
      'beef_product_brandings.beef_product_branding_id.translations.description',
      'beef_product_brandings.beef_product_branding_id.translations.id',
      'beef_product_brandings.beef_product_branding_id.translations.languages_code',
      'beef_product_brandings.beef_product_branding_id.translations.name',
      'beef_product_brandings.beef_product_branding_id.retailers.retailer_id',
      'beef_product_brandings.beef_product_branding_id.retailer',
      'beef_product_brandings.beef_product_branding_id.status',
      'beef_product_overrides.retailer',
      'beef_product_overrides.status',
      'beef_product_overrides.translations.description',
      'beef_product_overrides.translations.languages_code',
      'beef_product_overrides.translations.name',
      'collections.*.*',
      'children.*.*',
      'children.recipes.recipe_id.retailers',
      'cooking_methods.id',
      'cooking_methods.cooking_method.translations.id',
      'cooking_methods.cooking_method.translations.languages_code',
      'cooking_methods.cooking_method.translations.Method',
      'cooking_methods.Recommended',
      'cooking_methods.translations.id',
      'cooking_methods.translations.languages_code',
      'cooking_methods.translations.Directions',
      'id',
      'image',
      'Nutrients.calories',
      'Nutrients.id',
      'Nutrients.translations.id',
      'Nutrients.translations.description',
      'Nutrients.translations.languages_code',
      'Nutrients.translations.macronutrients',
      'Nutrients.translations.micronutrients',
      'Nutrients.translations.portion_size',
      'svg_icon',
      'translations.id',
      'translations.description',
      'translations.languages_id',
      'translations.name',
      'translations.tips',
    ];

    const beefCut = await api.getBeefProduct(beefProductId, fields.join(','));

    beefCut.name = getTranslation(
      getBeefProductOverride(beefCut, retailer, language),
      {
        key: 'languages_code',
        code: language.code
      }).name ||
      getTranslation(
        beefCut,
        {
          key: 'languages_id',
          code: language.code
        }).name;

    beefCut.description = getTranslation(
      getBeefProductOverride(beefCut, retailer, language),
      {
        key: 'languages_code',
        code: language.code
      }).description ||
      getTranslation(
        beefCut,
        {
          key: 'languages_id',
          code: language.code
        }).description;

    return beefCut;
  };

  useEffect(async() => {
    try {
      window.scrollTo(0, 0);

      setLoading(true);
      const getBeefProductResponse = await getBeefProduct(beefProductId);
      setBeefProduct(getBeefProductResponse);
    } catch(error) {
      error?.map((err) => notify('error', err.message));
    } finally {
      setLoading(false);
    }
  }, [beefProductId]);

  if (loading) {
    return <Loader />
  }
  
  return (
    <Box
      className="beef-product"
    >
      <Container maxWidth disableGutters
        sx={{ p: 0 }}
      >
        <Box>
          <PageHeader item={{ 
            name: beefProduct.name,
            recipe: [],
            svg_icon: beefProduct.svg_icon
          }}
          useAsLink={false}
        />
        </Box>
      </Container>

      <Container
        fixed
        className='padding-on-mobile'
        sx={{
            mt: 5
        }}
      >
        <Box>
            <Typography
                className='search-message'
                sx={{
                    color: '#000',
                    fontWeight: 300,
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
                component="div"
            >
                <DescriptionHolder content={beefProduct.description} />
            </Typography>
        </Box>
        
        { !!beefProduct?.image &&
          <Stack
              alignItems={'center'}
              justifyContent={'center'}
          >
              <Grid
                  className='beef-product_img_container'
                  item
                  sm={12} md={8} lg={6}
                  sx={{
                      mb: 3,
                  }}
              >
                  <img 
                      className="beef-product_img"
                      src={`${process.env.REACT_APP_API_URL}/assets/${beefProduct.image}?quality=50`}
                      alt={beefProduct.name} 
                  />
              </Grid>
          </Stack>
        }
      </Container>

      <Container
        fixed
        className='padding-on-mobile'
        sx={{
          my: 5
        }}
      >

        <BeefCuts
          beefCuts={beefProduct.children || []}
          sortingFunction={(a, b) => {
            const nameA = getTranslation(a, { key: 'languages_id', code: language.code }).name;
            const nameB = getTranslation(b, { key: 'languages_id', code: language.code }).name;

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }}
        />

        {
          beefProduct.collections?.map(collection => (
            <Box
              key={collection.id}
            >
              <AccordionSection
                title={getTranslation(collection, { key: 'languages_code', code: language.code}).title}
                expanded={true}
              >
                <Collection
                  id={collection.id}
                  content
                  image
                />
              </AccordionSection>
            </Box>
          ))
        }
      </Container>
    </Box>
  )
}

export default BeefRetail