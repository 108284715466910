import { Link } from 'react-router-dom';

import { bool, string } from 'prop-types';

import { Box, Card, CardContent, Stack, Typography } from '@mui/material';

import BackgroundImage from '../../shared/img/background-book.png';

import { useRetailerSettings } from '../../hooks/useRetailerSettings';

import styles from './index.module.scss';

const FullWidthCard = ({ url, label, image, externalLink }) => {
  const { retailerSettings, error: retailerSettingsError, isLoading: retailerSettingsLoading } = useRetailerSettings();

  const backgroundImage = image || BackgroundImage;
  
  const cardContent = (
    <Box
      className={styles.card}
      sx={{
        position: 'relative',
        mb: 2,
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundBlendMode: "lighten",
        backgroundSize: "cover",
        height: "200px",
      }}
    >
      <Card
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 0
        }}>
        <CardContent
          sx={{
            p: 0,
            background: retailerSettings?.brand_color_2 || '#000',
          }}
        >
          <Stack
            sx={{
              px: '10px',
              py: '8px',
            }}
            direction="row"
            alignItems="center"
          >
            <Typography
              className="text-primary"
              sx={{
                fontSize: '1.2rem',
                lineHeight: 1,
                fontWeight: 'bold',
                color: '#fff',
                textAlign: "center",
                width: "100%"
              }}
              variant='h2'
            >
              {label}
            </Typography>
          </Stack>
        </CardContent>
        <CardContent>
          <Stack sx={{ mt: 2, height: '108px', }}></Stack>
        </CardContent>
      </Card>
    </Box>
  );

  return (
    <>
      { !!externalLink &&
        <a href={url}>
          {cardContent}
        </a>
      }
      { !!!externalLink &&
        <Link to={url}>
          {cardContent}
        </Link>
      }
    </>
  );
};

FullWidthCard.propTypes = {
  url: string.isRequired,
  label: string.isRequired,
  image: string,
  externalLink: bool
};

export default FullWidthCard;
