import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { KeyboardArrowUpRounded } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import Wysiwyg from "../../Wysiwyg";
import { AwardsContext } from "../../../context/AwardsContext";
import RetailerContext from "../../../context";
import AccordionSection from "../AccordionSection";

function Achievement({ item }) {
    const { t } = useTranslation(['gamification']);

    const { retailer } = useContext(RetailerContext);
    const { setModalToOpenWithItem } = useContext(AwardsContext);

    return (
        <Box>
            {
                <AccordionSection
                    title={item.title}
                    subtext={((item.points / item.target) * 100) >= 100 ? t('completed') : t('notCompleted')}
                    subtextColour={((item.points / item.target) * 100) >= 100 ? '#008F01' : '#B92534'}
                >
                    <Box
                        sx={{}}
                    >
                        <Box>
                            <Grid
                                container
                            >
                                <Grid
                                    display='flex'
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Box
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            textAlign: 'center',
                                            mr: 0.5,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: {
                                                    xs: 'auto',
                                                },
                                                width: '100%',
                                                py: 1,
                                            }}
                                        >
                                            <img
                                                src={item.imageUrl}
                                                alt={'trophy'}
                                                style={{
                                                    height: '200px',
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                    borderRadius: '0',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid
                                    display='flex'
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                }
                                            }}
                                        >
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                                justifyContent={'space-between'}
                                                sx={{
                                                    width: '100%',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        textTransform: 'uppercase',
                                                        px: 0.5,
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        lineHeight: '100%',
                                                        fontSize: '1rem',
                                                        textTransform: 'uppercase',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <KeyboardArrowUpRounded />
                                                </Typography>
                                            </Stack>
                                        </Box>    
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Typography
                                className="achievements-wysiwyg"
                                component={'div'}
                                sx={{
                                    fontSize: '1rem',
                                    lineHeight: '1.1rem',
                                    textAlign: 'center',
                                    '& p': {
                                        m: 0,
                                    },
                                }}
                            >
                                <Wysiwyg>
                                    {item.message}
                                </Wysiwyg>
                            </Typography>

                            {
                                ((item.points / item.target) * 100) >= 100 ?
                                    (
                                        <></>
                                    ) :
                                    (
                                        <Button
                                            variant="contained"
                                            color="success"
                                            sx={{
                                                borderRadius: '0',
                                                backgroundColor: '#008F01',
                                                fontFamily: retailer.button_font,
                                                textTransform: 'uppercase',
                                                fontWeight: 'bold',
                                                width: '100%',
                                                my: 1,
                                            }}
                                            onClick={() => setModalToOpenWithItem(item.modalToOpen, item.achievement)}
                                        >
                                            {item.buttonText || ''}
                                        </Button>
                                    )
                            }
                            {
                                            ((item.points / item.target) * 100) >= 100 ?
                                            (
                                                <Box
                                                    sx={{
                                                        height: '1rem',
                                                        borderRadius: '0',
                                                        textAlign: 'center',
                                                        backgroundColor: '#ffffff',
                                                        color: '#008F01',
                                                        textTransform: 'uppercase',
                                                        mb: {
                                                            xs: '0.5rem',
                                                        },
                                                        mt: '0.5rem'
                                                    }}
                                                >
                                                    <Typography
                                                    >
                                                        {t('completed')}
                                                    </Typography>
                                                </Box>
                                            ) :
                                            (
                                                <></>
                                            )
                                        }
                        </Box>
                    </Box>
                </AccordionSection>
            }
        </Box>
    )
}

export default Achievement;