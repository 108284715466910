import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const TOKEN = process.env.REACT_APP_ACCESS_TOKEN;

export const fetcher = (payload) => {
    const { url, ...args} = payload;
    
    return axios.get(`${BASE_URL}/items/${url}`, {
        params: {
            access_token: TOKEN,
            ...args
        },
    })
    .then(res => res.data.data);
}