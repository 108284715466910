import { Container, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import Collections from '../../components/Collections';
import Wysiwyg from '../../components/Wysiwyg';

function Cuisines(props) {
  const { cuisines, title, description, imageUrl } = props;

  return (
    <Box>
      {!!imageUrl &&
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            className='collection_img_container'
            item
            sm={12}
            sx={{
              my: 3,
            }}
          >
            <img
              className="collection_img"
              src={imageUrl}
              alt={title}
            />
          </Grid>
        </Stack>
      }

      <Typography
        sx={{
          textAlign: 'justify'
        }}
        component="div"
      >
        <Wysiwyg>
          {description}
        </Wysiwyg>
      </Typography>

      <Collections collections={cuisines} />
    </Box>
  )
}

Cuisines.propTypes = {
  cuisines: PropTypes.array,
  description: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default Cuisines