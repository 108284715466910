import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import API from '../api';
import notify from '../components/Notification/helper';
import { useGetShortCode } from "./useGetShortCode";
import { setShortCode } from "../utils/gamification";
import { useUpdatePageViewsCount } from "./useUpdatePageViewsCount";

export const useLogPageView = retailerId => {
    if (!retailerId) return;

    let shortCode = useGetShortCode();
    const location = useLocation();
    useUpdatePageViewsCount();
    
    useEffect(async () => {
        const geolocationResponse = { data: {} };
        
        const geolocationDetails = geolocationResponse.data;

        if (!shortCode) {
            shortCode = await API.getShortCode();
            setShortCode(shortCode);
        }
        
        try {
            // build log payload
            const pageViewPayload = {
                user_agent: navigator.userAgent?.substring(0, 255),
                url: location.pathname,
                retailer: retailerId,
                ip: geolocationDetails.ip,
                country: geolocationDetails?.location?.country,
                region: geolocationDetails?.location?.region,
                short_codes: shortCode,
            }
            
            // post page view log
            await API.postLoadPageViewAnalytics(pageViewPayload)
        } catch (error) {
            error.map((err) => notify('error', err.message));
        } finally {}
    }, []);
}