import { Box, Grid } from "@mui/material"
import LazyLoad from "react-lazyload"
import CollectionCard from "../../CollectionCard"
import RecipeTile from "../../RecipeTile"

function Collections({ collections }) {

    const filteredCollections = collections
        .filter(collection => collection.status === 'published')

    return (
        <Grid container
            spacing={1}
            sx={{
                pt: 2
            }}
        >
            {
                filteredCollections?.map(collection => (
                    <Grid
                        item
                        key={collection.id}
                        xs={12}
                        md={6}
                        lg={6}
                    >
                        <LazyLoad
                            height={330}
                            once={true}
                        >
                            <CollectionCard collection={collection} />
                        </LazyLoad>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default Collections