import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { t } from 'i18next';
import useSWR from 'swr';

import { Container, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';

import { fetcher } from '../../utils/fetcher';
import { getTranslation } from '../../utils/translate';

import PageHeader from '../../components/PageHeader';
import AccordionSection from '../../components/AccordionSection';
import Loader from '../../components/Loader';

import ListItem from '../../components/ListItem';
import Search from '../../components/Search';
import RetailerContext from '../../context';
import NoResultsFound from '../../components/NoResultsFound';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { getAuthorDisplayName } from '../../utils/author';
import { useUpdateDocumentMeta } from '../../hooks/useUpdateDocumentMeta';

function Articles() {
  const { code:languageCode } = useSelector(state => state.language);
  const { retailer } = useContext(RetailerContext);

  const [isSearchActive, setIsSearchActive] = useState(false);

  const categoryId = useParams().categoryId;
  const authorId = useParams().authorId;

  const articleFieldsToFetch = [
    '*',
    'authors.*',
    'category.*',
    'category.translations.*',
    'translations.*',
    'retailers.retailer_id',
    'authors.recipe_author_id.*',
  ];

  const authorFieldsToFetch = [
    '*',
    'pages.pages_id.*',
  ];

  const categoryFieldsToFetch = [
    '*',
    'pages.*',
    'pages.retailers.retailer_id',
    'translations.*',
  ];

  const authorPublicationDateFilters = [
    {
      _or: [
        {
          pages: {
            pages_id: {
              date_published: {
                _lte: '$NOW'
              }
            },
          }
        },
        {
          pages: {
            pages_id: {
              date_published: {
                _null: true
              }
            },
          }
        },
      ]
    },
    {
      _or: [
        {
          pages: {
            pages_id: {
              date_expired: {
                _gte: '$NOW'
              }
            },
          }
        },
        {
          pages: {
            pages_id: {
              date_expired: {
                _null: true
              }
            },
          }
        },
      ]
    }
  ];

  const categoryPublicationDateFilters = [
    {
      _or: [
        {
          pages: {
            date_published: {
              _lte: '$NOW'
            }
          }
        },
        {
          pages: {
            date_published: {
              _null: true
            }
          }
        },
      ]
    },
    {
      _or: [
        {
          pages: {
            date_expired: {
              _gte: '$NOW'
            },
          }
        },
        {
          pages: {
            date_expired: {
              _null: true
            },
          }
        },
      ]
    }
  ];

  const articlesFilter = {
    _and: [
      {
        status: {
          _eq: 'published'
        }
      },
      {
        _or: [
          {
            "date_published": {
              "_lte": "$NOW"
            }
          },
          {
            "date_published": {
              "_null": true
            }
          },
        ]
      },
      {
        _or: [
          {
            "date_expired": {
              "_gte": "$NOW"
            }
          },
          {
            "date_expired": {
              "_null": true
            }
          },
        ]
      },
      ...(
        !!categoryId ? [{
          category: {
            _eq: categoryId
          }
        }] : []
      ),
      ...(
        !!authorId ? [{
          authors: {
            recipe_author_id: {
              _eq: authorId
            }
          }
        }] : []
      )
    ]
  }

  const categoriesFilter = {
    _and: [
      {
        status: {
          _eq: 'published'
        }
      },
      {
        'count(pages)': {
          _gte: 1
        }
      },
      {
        pages: {
          retailers: {
            retailer_id: {
              _eq: retailer.id
            }
          }
        }
      },
      ...(categoryPublicationDateFilters),
    ]
  }

  const authorsFilter = {
    _and: [
      {
        status: {
          _eq: 'published'
        }
      },
      {
        'count(pages)': {
          _gte: 1
        }
      },
      {
        pages: {
          pages_id: {
            retailers: {
              retailer_id: {
                _eq: retailer.id
              }
            }
          },
        }
      },
      ...(authorPublicationDateFilters),
    ]
  }

  const { data:articles, error:articlesError, isLoading:areArticlesLoading } = useSWR({ url: `pages`, fields: articleFieldsToFetch, filter: articlesFilter }, fetcher);
  const { data:authors, error:authorsError, isLoading:areAuthorsLoading } = useSWR({ url: `recipe_author`, fields: authorFieldsToFetch, filter: authorsFilter }, fetcher);
  const { data:categories, error:categoriesError, isLoading:areCategoriesLoading } = useSWR({ url: `categories`, fields: categoryFieldsToFetch, filter: categoriesFilter }, fetcher);
  const { data:category, error:categoryError, isLoading:isCategoryLoading } = useSWR(categoryId ? { url: `categories/${categoryId}`, fields: categoryFieldsToFetch } : null, fetcher);
  const { data:author, error:authorError, isLoading:isAuthorLoading } = useSWR(authorId ? { url: `recipe_author/${authorId}`, fields: authorFieldsToFetch } : null, fetcher);

  let browserTitle = t('articles');

  // if author is defined set browser title to author name
  if (author) {
    browserTitle = `${t('articlesBy')} ${getAuthorDisplayName(author)}`;
  }

  // if category is defined set browser title to category name
  if (category) {
    browserTitle = `${t('articlesIn')} ${getTranslation(category, { key: 'languages_code', code: languageCode })?.name}`;
  }  

  useUpdateDocumentMeta(browserTitle);

  const handleSearchTriggered = () => {
    setIsSearchActive(true);
  };

  const handleSearchCleared = async () => {
    setIsSearchActive(false);
  };

  const articleNavigationContent = (
    <>
      <AccordionSection
        title={t('categories')}
        expanded
      >
        {
          categories?.map(category => (
            <Box
              key={category.id}
              sx={{}}
            >
              <ListItem
                variant='minimal'
                url={`/articles/category/${category.id}`}
                title={getTranslation(category, { key: 'languages_code', code: languageCode })?.name}
                description={getTranslation(category, { key: 'languages_code', code: languageCode })?.description}
              />
            </Box>
          ))
        }
      </AccordionSection>

      <AccordionSection
        title={t('authors')}
        expanded
      >
        {
          authors?.map(author => (
            <Box
              key={author.id}
              sx={{}}
            >
              <ListItem
                variant='compact'
                url={`/articles/author/${author.id}`}
                image={`${process.env.REACT_APP_API_URL}/assets/${author.image}?key=page-hero-image-contain`}
                subtitle={author.credentials}
                title={getAuthorDisplayName(author)}
              />
            </Box>
          ))
        }
      </AccordionSection>
    </>
  )

  return (
    <Box
      className={'author'}
    >
      <Container maxWidth disableGutters
        sx={{
          p: 0
        }}
      >
        <Box>
          <PageHeader
            item={{ 
              name: t('articles'),
            }}
            useAsLink={false}
          />
        </Box>
      </Container>

      <Container
        sx={{
          my: 3,
        }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={7}
            lg={8}
          >
            <Search
              compact
              handleSearchTriggered={handleSearchTriggered}
              handleSearchCleared={handleSearchCleared}
              handleResultsFound={handleSearchTriggered}
            />

            {
              !isSearchActive &&
              <Box>
                <Box  
                  sx={{
                    mt: 3,
                  }}
                >
                  <Breadcrumbs
                    home
                    links={[
                      {
                        url: '/articles',
                        content: t('articles')
                      },
                      ...(categoryId ? [
                        {
                          content: getTranslation(category, { key: 'languages_code', code: languageCode })?.name,
                          url: `/articles/category/${categoryId}`,
                        }
                      ] : []),
                      ...(authorId ? [
                        {
                          content: getAuthorDisplayName(author),
                          url: `/articles/author/${authorId}`,
                        }
                      ] : [])
                    ]}
                  />
                </Box>

                {
                  !!areArticlesLoading &&
                  <Loader compact />
                }

                {
                  !!!(
                    articles
                      ?.filter(article => {
                        const retailerIds = article.retailers.map(retailer => retailer.retailer_id)
                        return retailerIds.includes(retailer.id)
                      })?.length
                    ) && !!!areArticlesLoading && 
                  <NoResultsFound />
                }

                <Box
                  sx={{
                    width: '100%',
                    display: {
                      md: 'none',
                      xs: 'block'
                    }
                  }}
                >
                  {articleNavigationContent}  
                </Box>

                <Box
                  sx={{
                    my: 2,
                  }}
                >                
                  {
                    articles
                      ?.filter(article => {
                        const retailerIds = article.retailers.map(retailer => retailer.retailer_id)
                        return retailerIds.includes(retailer.id)
                      })
                      ?.map(article => (
                        <Box
                          key={article.id}
                          sx={{
                            mb: 2,
                          }}
                        >
                          <ListItem
                            url={`/article/${article.id}`}
                            variant='column'
                            image={`${process.env.REACT_APP_API_URL}/assets/${article.image}?key=page-hero-image-contain`}
                            subtitle={getTranslation(article.category, { key: 'languages_code', code: languageCode}).name}
                            title={getTranslation(article, { key: 'languages_code', code: languageCode}).title}
                            description={getTranslation(article, { key: 'languages_code', code: languageCode}).summary}
                            footer={(
                              <Stack
                                sx={{
                                  mt: 2,
                                }}
                              >
                                {
                                  article?.authors.map(({recipe_author_id:author}) => (
                                    <ListItem
                                      variant='compact'
                                      key={author.id}
                                      url={`/author/${author.id}`}
                                      image={`${process.env.REACT_APP_API_URL}/assets/${author.image}?key=page-hero-image-contain`}
                                      title={getAuthorDisplayName(author)}
                                      subtitle={author.credentials}
                                    />
                                  ))
                                }
                              </Stack>
                            )}
                          />
                        </Box>
                      ))
                  }
                </Box>
              </Box>
            }
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            lg={4}
          >
            <Box
              sx={{
                display: {
                  md: 'block',
                  xs: 'none'
                }
              }}
            >
              {articleNavigationContent}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Articles