import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import api from "../../api";
import { getTranslation } from "../../utils/translate";

import Loader from "../Loader";
import notify from "../Notification/helper";

import SearchNav from "../SearchNav";

import BeefCuts from "../BeefCuts";
import NoResultsFound from "../NoResultsFound";
import ListView from "../ListView";
import RetailerContext from "../../context";

import { bool, func, string } from "prop-types";
import { getAuthorDisplayName } from "../../utils/author";
import { pushToGoogleAnalytics } from "../../utils/google-analytics";

import './index.scss';
import { useQuery } from "../../hooks/useQuery";

const buildFilterParams = (filters) => {
  // build filter params
  const filterParams = [];
  const activeFilters = [];

  if (!!filters.prepTime) {
    activeFilters.push('prepTime');

    filterParams.push({
      prep: {
        '_lte': filters.prepTime
      }
    })
  }

  if (!!filters.cookTime) {
    activeFilters.push('cookTime');

    filterParams.push({
      cooking: {
        '_lte': filters.cookTime
      }
    })
  }

  if (!!filters.chillTime) {
    activeFilters.push('chillTime');

    filterParams.push({
      chill_time: {
        '_lte': filters.chillTime
      }
    })
  }

  if (!!filters.freezingTime) {
    activeFilters.push('freezingTime');

    filterParams.push({
      freezing_time: {
        '_lte': filters.freezingTime
      }
    })
  }

  if (!!filters.totalTime) {
    activeFilters.push('totalTime');

    filterParams.push({
      total_time: {
        '_lte': filters.totalTime
      }
    })
  }

  if (filters.difficulty > -1) {
    activeFilters.push('difficulty');

    filterParams.push({
      difficulty: {
        '_eq': filters.difficulty
      }
    })
  }

  if (filters.categoryCheckedState?.filter(item => item).length) {
    activeFilters.push('categories');

    filterParams.push({
      category: {
        recipe_categories_id: {
          '_in': filters.categoryCheckedState?.filter(item => item)
        }
      }
    })
  }

  if (!!filters.recipeYield) {
    activeFilters.push('servings');

    filterParams.push({
      yields: {
        '_lte': filters.recipeYield
      }
    })
  }

  if (filters.ingredients) {
    activeFilters.push('ingredients');

    filterParams.push({
      layout_translations: {
        'count(ingredients_list)': {
          '_lte': filters.ingredients
        }
      }
    });
  }

  return { filterParams, activeFilters };
};

function Search({
    handleSearchTriggered = () => {},
    handleSearchCleared = () => {},
    handleSearchComplete = () => {},
    storageKey = 'searchQuery',
    compact=false,
  }) {
  const language = useSelector(state => state.language);
  const { retailer } = useContext(RetailerContext);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const [collections, setCollections] = useState([]);
  const [beefCuts, setBeefCuts] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [articles, setArticles] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [recipeAuthors, setRecipeAuthors] = useState([]);
  const [searchQuery, setSearchQuery] = useState(() => window.localStorage.getItem(storageKey) || '');

  const query = useQuery();

  const filters = JSON.parse(query.get('filter') || JSON.stringify({}));
  const searchParam = query.get('q') || '';

  const { filterParams, activeFilters } = buildFilterParams(filters);

  useEffect(() => {
    window.localStorage.setItem(storageKey, searchQuery);
  }, [searchQuery]);
  
  const apiFieldsToFetch = [
    '*',
    'translations.name',
    'beef_products.*.*',
    'beef_products.beef_product_id.retailers.retailer_id',
    'beef_products.beef_product_id.translations.*',
    'collections.collections_id.*.*',
    'pages.pages_id.*',
    'pages.pages_id.translations.*',
    'recipes.recipe_id.*.*',
    'recipes.*.*',
    'recipe_authors.*.*',
  ];

  const handleResultClick = () => {
    clearSearch();
  }

  const checkIfFullMatch = (searchString, dataset, fields=[]) => {
    const searchRegex = new RegExp(`\\b(${searchString})\\b`, 'i');

    // combine text for different translations
    const combinedTextToSearch = dataset.map(dataItem => {
      return fields.map(field => dataItem[field]);
    })?.join(' ');

    const fullMatch = searchRegex.test(combinedTextToSearch);

    return fullMatch;
  }

  const searchArticles = async (searchString, retailerId) => {
    const apiFields = [
      '*',
      'translations.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
              retailer_id: {
                  '_eq': retailerId
              }
          }
        },
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchArticleFieldsPayload = {
      "_and": [
        {
          "_or": [
            {
              "translations": {
                  "title": {
                    "_contains": searchString
                  }
              }
            },
            {
              "translations": {
                  "summary": {
                    "_contains": searchString
                  }
              }
            },
          ]
        },
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
      ]
    };

    const articleSearchResults = await api.searchArticles({ filter: searchArticleFieldsPayload }, apiFields);

    return articleSearchResults;
  }

  const searchRecipes = async (searchString, filterParams, retailerId) => {
    const apiFields = [
      '*',
      '*.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
              retailer_id: {
                  '_eq': retailerId
              }
          }
        },
        {
          retailer: {
              id: {
                '_eq': retailerId
              }
          }
        }
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchRecipeFieldsPayload = {
      "_and": [
        ...(searchString ? [
          {
            "_or": [
              {
                "layout_translations": {
                  "name": {
                    "_contains": searchString
                  }
                }
              },
              {
                "layout_translations": {
                  "summary": {
                    "_contains": searchString
                  }
                }
              },
            ]
          },
        ] : []),
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
        ...filterParams,
      ]
    };

    const recipeSearchResults = await api.searchRecipes({
      filter: searchRecipeFieldsPayload,
      limit: 25,
      sort: '-date_created'
    }, apiFields);

    return recipeSearchResults;
  }

  const searchCollections = async (searchString) => {
    const apiFields = [
      '*',
      '*.*',
    ];

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchCollectionFieldsPayload = {
      "_and": [
        {
          "_or": [
            {
              "translations": {
                  "title": {
                    "_contains": searchString
                  }
              }
            },
            {
              "translations": {
                  "content": {
                    "_contains": searchString
                  }
              }
            },
          ]
        },
        ...[statusFilterPayload],
      ]
    };

    const collectionSearchResults = await api.searchCollections({ filter: searchCollectionFieldsPayload }, apiFields);

    return collectionSearchResults;
  }

  const searchAuthors = async (searchString) => {
    const apiFields = [
      '*',
      'translations.*',
    ];

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchAuthorFieldsPayload = {
      "_and": [
        ...[statusFilterPayload],
      ]
    };

    const recipeAuthorSearchResults = await api.searchRecipeAuthors({
      filter: searchAuthorFieldsPayload,
      search: searchString
    }, apiFields);

    return recipeAuthorSearchResults;
  }

  const searchBeefCuts = async (searchString, retailerId) => {
    const apiFields = [
      '*',
      'translations.*',
      'retailers.*',
      'beef_product_overrides.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
              retailer_id: {
                  '_eq': retailerId
              }
          }
        },
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchBeefCutFieldsPayload = {
      "_and": [
        {
          "_or": [
            {
              "translations": {
                  "name": {
                    "_contains": searchString
                  }
              }
            },
            {
              "translations": {
                  "description": {
                    "_contains": searchString
                  }
              }
            },
          ]
        },
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
      ]
    };

    // search recipes by tags first
    const beefCutSearchResults = await api.searchBeefCuts({ filter: searchBeefCutFieldsPayload }, apiFields);

    return beefCutSearchResults;
  }

  const clearSearch = () => {
    handleSearchCleared();
  };

  const processTagsSearchResults = (tags) => {
    let articleResults = [];
    let beefCutResults = [];
    let cuisineResults = [];
    let recipeAuthorResults = [];
    let recipeResults = [];

    // group results
    tags?.forEach(tag => {
      const {
        beef_products:beefProductsTagsJunction,
        pages:articlesTagsJunction,
        collections:collectionsTagsJunction,
        recipes:recipesTagsJunction,
        recipe_authors:recipeAuthorsTagsJunction,
      } = tag;
      
      beefCutResults = [
        ...beefCutResults,
        ...beefProductsTagsJunction?.map(beefProductsTagsJunctionItem => beefProductsTagsJunctionItem.beef_product_id)
      ];

      cuisineResults = [
        ...cuisineResults,
        ...collectionsTagsJunction
          ?.map(collectionsTagsJunctionItem => collectionsTagsJunctionItem.collections_id)
          ?.filter(cuisine => cuisine?.status === 'published')
          ?.map(cuisine => {
            const translatedCuisine = getTranslation(cuisine, { key: 'languages_code', code: language.code });

            return {
              description: translatedCuisine.content,
              handleClick: handleResultClick,
              image: translatedCuisine.image ?
              `${process.env.REACT_APP_API_URL}/assets/${translatedCuisine.image}?key=system-small-cover` :
              null,
              subtitle: ['CUISINE', 'CUISINES'].includes(cuisine.type) ? t('cuisine') : t('collection'),
              title: translatedCuisine.title,
              url: `/collections/${cuisine.id}`,
            }
          })
      ];

      articleResults = [
        ...articleResults,
        ...articlesTagsJunction
          ?.map(articlesTagsJunctionItem => articlesTagsJunctionItem.pages_id)
          ?.filter(article => article?.status === 'published')
          ?.map(article => {
            const translatedArticle = getTranslation(article, { key: 'languages_code', code: language.code });

            return {
              description: translatedArticle.summary,
              handleClick: handleResultClick,
              image: article.image ?
              `${process.env.REACT_APP_API_URL}/assets/${article.image}?key=system-small-cover` :
              null,
              subtitle: t('article'),
              title: translatedArticle.title,
              url: `/article/${article.id}`,
            }
          })
      ];

      recipeAuthorResults = [
        ...recipeAuthorResults,
        ...recipeAuthorsTagsJunction
          ?.map(recipeAuthorsTagsJunctionItem => recipeAuthorsTagsJunctionItem.recipe_author_id)
          ?.filter(author => author?.status === 'published') // this stage will also handle null values
          ?.map(author => ({
            description: getTranslation(author, { key: 'languages_code', code: language.code })?.biography,
            handleClick: handleResultClick,
            image: author.image ?
              `${process.env.REACT_APP_API_URL}/assets/${author.image}?key=system-small-cover` :
              null,
            subtitle: t('author'),
            title: getAuthorDisplayName(author),
            url: `/author/${author.id}`
          })) || []
      ];

      recipeResults = [
        ...recipeResults,
        ...recipesTagsJunction
          ?.map(recipesTagsJunctionItem => recipesTagsJunctionItem.recipe_id)
          ?.filter(recipe => recipe?.status === 'Published')
          ?.map(recipe => {
            const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
            const recipeImage = recipe?.imagegallery?.length ?
              `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
              null;

            return {
              description: translatedRecipe.summary,
              handleClick: handleResultClick,
              image: recipeImage,
              subtitle: t('recipe'),
              title: translatedRecipe.name,
              url: `/recipe/${recipe.id}`
            }
          })
      ];
    });

    return {
      articleResults,
      beefCutResults,
      cuisineResults,
      recipeAuthorResults,
      recipeResults
    }
  }

  const triggerSearch = async () => {
    try {
      const searchString = searchParam;
      setLoading(true);
      setNoResultsFound(false);
      handleSearchTriggered();

      const tagsResponse = await api.searchTags(searchString, apiFieldsToFetch);

      // filter out results that are not full match
      const tagHits = tagsResponse.filter(tag => checkIfFullMatch(searchString, tag.translations, ['name']));

      let {
        articleResults,
        beefCutResults,
        cuisineResults,
        recipeAuthorResults,
        recipeResults
    } = processTagsSearchResults(tagHits);

      // if tags search returned no hits, then search beef cuts localised fields
      if (!beefCutResults.length) {
        beefCutResults = (await searchBeefCuts(searchString, retailer.id))
          .filter(beefCut => checkIfFullMatch(searchString, beefCut.translations, ['name', 'description']))
      }

      // if tags search returned no hits, then search article localised fields
      if (!articleResults.length) {
        articleResults = (await searchArticles(searchString, retailer.id))
          .filter(article => checkIfFullMatch(searchString, article.translations, ['title', 'summary']))
          ?.map(article => {
            const translatedArticle = getTranslation(article, { key: 'languages_code', code: language.code });

            return {
              description: translatedArticle.summary,
              handleClick: handleResultClick,
              image: article.image ?
              `${process.env.REACT_APP_API_URL}/assets/${article.image}?key=system-small-cover` :
              null,
              subtitle: t('article'),
              title: translatedArticle.title,
              url: `/article/${article.id}`
            }
          });
      }

      // if tags search returned no hits, then search cuisine localised fields
      if (!cuisineResults.length) {
        cuisineResults = (await searchCollections(searchString))
          .filter(collection => checkIfFullMatch(searchString, collection.translations, ['title', 'content']))
          ?.map(collection => {
            const translatedCollection = getTranslation(collection, { key: 'languages_code', code: language.code });

            return {
              description: translatedCollection.content,
              handleClick: handleResultClick,
              image: translatedCollection.image ?
              `${process.env.REACT_APP_API_URL}/assets/${translatedCollection.image}?key=system-small-cover` :
              null,
              subtitle: ['CUISINE', 'CUISINES'].includes(collection.type) ? t('cuisine') : t('collection'),
              title: translatedCollection.title,
              url: `/collections/${collection.id}`
            }
          });
      }

      // if tags search returned no hits, then search author fields
      if (!recipeAuthorResults.length) {
        recipeAuthorResults = (await searchAuthors(searchString))
          .filter(author => checkIfFullMatch(searchString, [author], ['first_name', 'last_name', 'biography']))
          ?.map(author => ({
            description: getTranslation(author, { key: 'languages_code', code: language.code })?.biography,
            handleClick: handleResultClick,
            image: author.image ?
              `${process.env.REACT_APP_API_URL}/assets/${author.image}?key=system-small-cover` :
              null,
            subtitle: t('author'),
            title: `${author.first_name} ${author.last_name}`,
            url: `/author/${author.id}`
          }))
      }

      // if tags search returned no hits, then search recipes
      if (!recipeResults.length) {
        recipeResults = (await searchRecipes(searchString, filterParams, retailer.id))
          .filter(recipe => checkIfFullMatch(searchString, recipe.layout_translations, ['name', 'summary']))
          ?.map(recipe => {
            const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
            const recipeImage = recipe?.imagegallery?.length ?
              `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
              null;

            return {
              description: translatedRecipe.summary,
              handleClick: handleResultClick,
              image: recipeImage,
              subtitle: t('recipe'),
              title: translatedRecipe.name,
              url: `/recipe/${recipe.id}`
            }
          });
      }

      setArticles(articleResults);
      setBeefCuts(beefCutResults);
      setCuisines(cuisineResults);
      setRecipeAuthors(recipeAuthorResults);
      setRecipes(recipeResults);

      // log to google analytics
      const resultsCount = articleResults.length + beefCutResults.length + cuisineResults.length + recipeAuthorResults.length + recipeResults.length;
      const googleAnalyticsPayload = {
        event: 'recipeSearch',
        searchQuery: searchString,
        numberOfResults: resultsCount
      };

      pushToGoogleAnalytics(googleAnalyticsPayload);

      // results have been found
      if (!!beefCutResults.length || !!cuisineResults.length || !!recipeAuthorResults.length || !!recipeResults.length) {
        handleSearchComplete();
        setNoResultsFound(false);
      } else {
        setNoResultsFound(true);
      }
    } catch(error) {
      error?.map((err) => notify('error', err.message));
    } finally {
      setLoading(false);
    }
  }
  
  return (
    <Box
      className="collection"
    >
        <SearchNav
          searchString={searchQuery}
          triggerSearch={triggerSearch}
          handleClearSearch={clearSearch}
          loading={loading}
          compact={compact}
        />

        {
          loading &&
          <Loader compact />
        }

        {
          !loading && noResultsFound &&
          <NoResultsFound />
        }

        {
          !loading &&
          <SearchResults
            key={`${JSON.stringify(filters)}${JSON.stringify(searchParam)}`}
            handleSearchTriggered={handleSearchTriggered}
            handleSearchCleared={handleSearchCleared}
            handleSearchComplete={handleSearchComplete}
          />
        }
    </Box>
  )
}

const SearchResults = ({
  handleSearchTriggered = () => { },
  handleSearchCleared = () => { },
  handleSearchComplete = () => { },
  storageKey = 'searchQuery',
  compact = false,
}) => {
  const language = useSelector(state => state.language);
  const { retailer } = useContext(RetailerContext);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const [collections, setCollections] = useState([]);
  const [beefCuts, setBeefCuts] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [articles, setArticles] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [recipeAuthors, setRecipeAuthors] = useState([]);
  const [searchQuery, setSearchQuery] = useState(() => window.localStorage.getItem(storageKey) || '');

  const query = useQuery();

  const filters = JSON.parse(query.get('filter') || JSON.stringify({}));
  const searchParam = query.get('q') || '';

  const { filterParams, activeFilters } = buildFilterParams(filters);

  useEffect(() => {
    window.localStorage.setItem(storageKey, searchQuery);
  }, [searchQuery]);

  const apiFieldsToFetch = [
    '*',
    // 'translations.name',
    // 'beef_products.*.*',
    // 'beef_products.beef_product_id.retailers.retailer_id',
    // 'beef_products.beef_product_id.translations.*',
    // 'collections.collections_id.*.*',
    // 'pages.pages_id.*',
    // 'pages.pages_id.translations.*',
    // 'recipes.recipe_id.*.*',
    // 'recipes.*.*',
    // 'recipe_authors.*.*',
  ];

  const handleResultClick = () => {
    clearSearch();
  }

  const checkIfFullMatch = (searchString, dataset, fields = []) => {
    const searchRegex = new RegExp(`\\b(${searchString})\\b`, 'i');

    // combine text for different translations
    const combinedTextToSearch = dataset.map(dataItem => {
      return fields.map(field => dataItem[field]);
    })?.join(' ');

    const fullMatch = searchRegex.test(combinedTextToSearch);

    return fullMatch;
  }

  const searchArticles = async (searchString, retailerId) => {
    const apiFields = [
      '*',
      'translations.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
            retailer_id: {
              '_eq': retailerId
            }
          }
        },
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchArticleFieldsPayload = {
      "_and": [
        ...(searchString ? [
          {
            "_or": [
              {
                "translations": {
                  "title": {
                    "_contains": searchString
                  }
                }
              },
              {
                "translations": {
                  "summary": {
                    "_contains": searchString
                  }
                }
              },
            ]
          },
        ] : []),
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
      ]
    };

    const articleSearchResults = await api.searchArticles({ filter: searchArticleFieldsPayload }, apiFields);

    return articleSearchResults;
  }

  const searchRecipes = async (searchString, filterParams, retailerId) => {
    const apiFields = [
      '*',
      '*.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
            retailer_id: {
              '_eq': retailerId
            }
          }
        },
        {
          retailer: {
            id: {
              '_eq': retailerId
            }
          }
        }
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchRecipeFieldsPayload = {
      "_and": [
        ...(searchString ? [
          {
            "_or": [
              {
                "layout_translations": {
                  "name": {
                    "_contains": searchString
                  }
                }
              },
              {
                "layout_translations": {
                  "summary": {
                    "_contains": searchString
                  }
                }
              },
            ]
          },
        ] : []),
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
        ...filterParams,
      ]
    };

    const recipeSearchResults = await api.searchRecipes({
      filter: searchRecipeFieldsPayload,
      limit: 25,
      sort: '-date_created'
    }, apiFields);

    return recipeSearchResults;
  }

  const searchCollections = async (searchString) => {
    const apiFields = [
      '*',
      '*.*',
    ];

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchCollectionFieldsPayload = {
      "_and": [
        ...(searchString ? [
          {
            "_or": [
              {
                "translations": {
                  "title": {
                    "_contains": searchString
                  }
                }
              },
              {
                "translations": {
                  "content": {
                    "_contains": searchString
                  }
                }
              },
            ]
          },
        ] : []),
        ...[statusFilterPayload],
      ]
    };

    const collectionSearchResults = await api.searchCollections({ filter: searchCollectionFieldsPayload }, apiFields);

    return collectionSearchResults;
  }

  const searchAuthors = async (searchString) => {
    const apiFields = [
      '*',
      'translations.*',
    ];

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchAuthorFieldsPayload = {
      "_and": [
        ...[statusFilterPayload],
      ]
    };

    const recipeAuthorSearchResults = await api.searchRecipeAuthors({
      filter: searchAuthorFieldsPayload,
      search: searchString
    }, apiFields);

    return recipeAuthorSearchResults;
  }

  const searchBeefCuts = async (searchString, retailerId) => {
    const apiFields = [
      '*',
      'translations.*',
      'retailers.*',
      'beef_product_overrides.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
            retailer_id: {
              '_eq': retailerId
            }
          }
        },
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchBeefCutFieldsPayload = {
      "_and": [
        ...(searchString ? [
          {
            "_or": [
              {
                "translations": {
                  "name": {
                    "_contains": searchString
                  }
                }
              },
              {
                "translations": {
                  "description": {
                    "_contains": searchString
                  }
                }
              },
            ]
          },
        ] : []
        ),
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
      ]
    };

    // search recipes by tags first
    const beefCutSearchResults = await api.searchBeefCuts({ filter: searchBeefCutFieldsPayload }, apiFields);

    return beefCutSearchResults;
  }

  const clearSearch = () => {
    setArticles([]);
    setBeefCuts([]);
    setCuisines([]);
    setCollections([]);
    setRecipes([]);
    setRecipeAuthors([]);

    setNoResultsFound(false);

    setSearchQuery('');

    handleSearchCleared();
  };

  const clearSearchResults = () => {
    setArticles([]);
    setBeefCuts([]);
    setCuisines([]);
    setCollections([]);
    setRecipes([]);
    setRecipeAuthors([]);

    setNoResultsFound(false);
  };

  const processTagsSearchResults = (tags) => {
    let articleResults = [];
    let beefCutResults = [];
    let cuisineResults = [];
    let recipeAuthorResults = [];
    let recipeResults = [];

    // group results
    tags?.forEach(tag => {
      const {
        beef_products: beefProductsTagsJunction,
        pages: articlesTagsJunction,
        collections: collectionsTagsJunction,
        recipes: recipesTagsJunction,
        recipe_authors: recipeAuthorsTagsJunction,
      } = tag;

      beefCutResults = [
        ...beefCutResults,
        ...beefProductsTagsJunction?.map(beefProductsTagsJunctionItem => beefProductsTagsJunctionItem.beef_product_id)
      ];

      cuisineResults = [
        ...cuisineResults,
        ...collectionsTagsJunction
          ?.map(collectionsTagsJunctionItem => collectionsTagsJunctionItem.collections_id)
          ?.filter(cuisine => cuisine?.status === 'published')
          ?.map(cuisine => {
            const translatedCuisine = getTranslation(cuisine, { key: 'languages_code', code: language.code });

            return {
              description: translatedCuisine.content,
              handleClick: handleResultClick,
              image: translatedCuisine.image ?
                `${process.env.REACT_APP_API_URL}/assets/${translatedCuisine.image}?key=system-small-cover` :
                null,
              subtitle: ['CUISINE', 'CUISINES'].includes(cuisine.type) ? t('cuisine') : t('collection'),
              title: translatedCuisine.title,
              url: `/collections/${cuisine.id}`,
            }
          })
      ];

      articleResults = [
        ...articleResults,
        ...articlesTagsJunction
          ?.map(articlesTagsJunctionItem => articlesTagsJunctionItem.pages_id)
          ?.filter(article => article?.status === 'published')
          ?.map(article => {
            const translatedArticle = getTranslation(article, { key: 'languages_code', code: language.code });

            return {
              description: translatedArticle.summary,
              handleClick: handleResultClick,
              image: article.image ?
                `${process.env.REACT_APP_API_URL}/assets/${article.image}?key=system-small-cover` :
                null,
              subtitle: t('article'),
              title: translatedArticle.title,
              url: `/article/${article.id}`,
            }
          })
      ];

      recipeAuthorResults = [
        ...recipeAuthorResults,
        ...recipeAuthorsTagsJunction
          ?.map(recipeAuthorsTagsJunctionItem => recipeAuthorsTagsJunctionItem.recipe_author_id)
          ?.filter(author => author?.status === 'published') // this stage will also handle null values
          ?.map(author => ({
            description: getTranslation(author, { key: 'languages_code', code: language.code })?.biography,
            handleClick: handleResultClick,
            image: author.image ?
              `${process.env.REACT_APP_API_URL}/assets/${author.image}?key=system-small-cover` :
              null,
            subtitle: t('author'),
            title: getAuthorDisplayName(author),
            url: `/author/${author.id}`
          })) || []
      ];

      recipeResults = [
        ...recipeResults,
        ...recipesTagsJunction
          ?.map(recipesTagsJunctionItem => recipesTagsJunctionItem.recipe_id)
          ?.filter(recipe => recipe?.status === 'Published')
          ?.map(recipe => {
            const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
            const recipeImage = recipe?.imagegallery?.length ?
              `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
              null;

            return {
              description: translatedRecipe.summary,
              handleClick: handleResultClick,
              image: recipeImage,
              subtitle: t('recipe'),
              title: translatedRecipe.name,
              url: `/recipe/${recipe.id}`
            }
          })
      ];
    });

    return {
      articleResults,
      beefCutResults,
      cuisineResults,
      recipeAuthorResults,
      recipeResults
    }
  }

  const triggerSearch = async () => {
    try {
      const searchString = searchParam;

      setLoading(true);
      setNoResultsFound(false);
      handleSearchTriggered();

      if (searchString) {
        const tagsResponse = await api.searchTags(searchString, apiFieldsToFetch);

        // filter out results that are not full match
        const tagHits = tagsResponse.filter(tag => checkIfFullMatch(searchString, tag.translations, ['name']));

        let {
          articleResults,
          beefCutResults,
          cuisineResults,
          recipeAuthorResults,
          recipeResults
        } = processTagsSearchResults(tagHits);

        // if tags search returned no hits, then search beef cuts localised fields
        if (!beefCutResults.length) {
          beefCutResults = (await searchBeefCuts(searchString, retailer.id))
            .filter(beefCut => checkIfFullMatch(searchString, beefCut.translations, ['name', 'description']))
        }

        // if tags search returned no hits, then search article localised fields
        if (!articleResults.length) {
          articleResults = (await searchArticles(searchString, retailer.id))
            .filter(article => checkIfFullMatch(searchString, article.translations, ['title', 'summary']))
            ?.map(article => {
              const translatedArticle = getTranslation(article, { key: 'languages_code', code: language.code });

              return {
                description: translatedArticle.summary,
                handleClick: handleResultClick,
                image: article.image ?
                  `${process.env.REACT_APP_API_URL}/assets/${article.image}?key=system-small-cover` :
                  null,
                subtitle: t('article'),
                title: translatedArticle.title,
                url: `/article/${article.id}`
              }
            });
        }

        // if tags search returned no hits, then search cuisine localised fields
        if (!cuisineResults.length) {
          cuisineResults = (await searchCollections(searchString))
            .filter(collection => checkIfFullMatch(searchString, collection.translations, ['title', 'content']))
            ?.map(collection => {
              const translatedCollection = getTranslation(collection, { key: 'languages_code', code: language.code });

              return {
                description: translatedCollection.content,
                handleClick: handleResultClick,
                image: translatedCollection.image ?
                  `${process.env.REACT_APP_API_URL}/assets/${translatedCollection.image}?key=system-small-cover` :
                  null,
                subtitle: ['CUISINE', 'CUISINES'].includes(collection.type) ? t('cuisine') : t('collection'),
                title: translatedCollection.title,
                url: `/collections/${collection.id}`
              }
            });
        }

        // if tags search returned no hits, then search author fields
        if (!recipeAuthorResults.length) {
          recipeAuthorResults = (await searchAuthors(searchString))
            .filter(author => checkIfFullMatch(searchString, [author], ['first_name', 'last_name', 'biography']))
            ?.map(author => ({
              description: getTranslation(author, { key: 'languages_code', code: language.code })?.biography,
              handleClick: handleResultClick,
              image: author.image ?
                `${process.env.REACT_APP_API_URL}/assets/${author.image}?key=system-small-cover` :
                null,
              subtitle: t('author'),
              title: `${author.first_name} ${author.last_name}`,
              url: `/author/${author.id}`
            }))
        }

        // if tags search returned no hits, then search recipes
        if (!recipeResults.length) {
          recipeResults = (await searchRecipes(searchString, filterParams, retailer.id))
            .filter(recipe => checkIfFullMatch(searchString, recipe.layout_translations, ['name', 'summary']))
            ?.map(recipe => {
              const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
              const recipeImage = recipe?.imagegallery?.length ?
                `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
                null;

              return {
                description: translatedRecipe.summary,
                handleClick: handleResultClick,
                image: recipeImage,
                subtitle: t('recipe'),
                title: translatedRecipe.name,
                url: `/recipe/${recipe.id}`
              }
            });
        }

        setArticles(articleResults);
        setBeefCuts(beefCutResults);
        setCuisines(cuisineResults);
        setRecipeAuthors(recipeAuthorResults);
        setRecipes(recipeResults);

        // log to google analytics
        const resultsCount = articleResults.length + beefCutResults.length + cuisineResults.length + recipeAuthorResults.length + recipeResults.length;
        const googleAnalyticsPayload = {
          event: 'recipeSearch',
          searchQuery: searchString,
          numberOfResults: resultsCount
        };

        pushToGoogleAnalytics(googleAnalyticsPayload);

        // results have been found
        if (!!beefCutResults.length || !!cuisineResults.length || !!recipeAuthorResults.length || !!recipeResults.length) {
          handleSearchComplete();
          setNoResultsFound(false);
        } else {
          setNoResultsFound(true);
        }
      } else {
        let [
          articleResults,
          beefCutResults,
          cuisineResults,
          recipeAuthorResults,
          recipeResults
        ] = Array(5).fill([]);

        // if tags search returned no hits, then search recipes
        if (!recipeResults.length) {
          recipeResults = (await searchRecipes(searchString, filterParams, retailer.id))
            .filter(recipe => checkIfFullMatch(searchString, recipe.layout_translations, ['name', 'summary']))
            ?.map(recipe => {
              const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
              const recipeImage = recipe?.imagegallery?.length ?
                `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
                null;

              return {
                description: translatedRecipe.summary,
                handleClick: handleResultClick,
                image: recipeImage,
                subtitle: t('recipe'),
                title: translatedRecipe.name,
                url: `/recipe/${recipe.id}`
              }
            });
        }

        setArticles(articleResults);
        setBeefCuts(beefCutResults);
        setCuisines(cuisineResults);
        setRecipeAuthors(recipeAuthorResults);
        setRecipes(recipeResults);

        // log to google analytics
        const resultsCount = articleResults.length + beefCutResults.length + cuisineResults.length + recipeAuthorResults.length + recipeResults.length;
        const googleAnalyticsPayload = {
          event: 'recipeSearch',
          searchQuery: searchString,
          numberOfResults: resultsCount
        };

        pushToGoogleAnalytics(googleAnalyticsPayload);

        // results have been found
        if (!!beefCutResults.length || !!cuisineResults.length || !!recipeAuthorResults.length || !!recipeResults.length) {
          handleSearchComplete();
          setNoResultsFound(false);
        } else {
          setNoResultsFound(true);
        }
      }
    } catch (error) {
      error?.map((err) => notify('error', err.message));
    } finally {
      setLoading(false);
    }
  }

  const searchUsingFilters = async (searchString, filterParams, retailerId) => {
    let results = {
      recipes: [],
      articles: [],
      beefCuts: [],
      cuisines: [],
      recipeAuthors: [],
    };

    // if no search string, then search recipes only. Using filters
    if (!searchString) {
      const recipeResults = (await searchRecipes(searchString, filterParams, retailerId))
        .filter(recipe => checkIfFullMatch(searchString, recipe.layout_translations, ['name', 'summary']))
        ?.map(recipe => {
          const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
          const recipeImage = recipe?.imagegallery?.length ?
            `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
            null;

          return {
            description: translatedRecipe.summary,
            handleClick: handleResultClick,
            image: recipeImage,
            subtitle: t('recipe'),
            title: translatedRecipe.name,
            url: `/recipe/${recipe.id}`
          }
        });

      results.recipes = recipeResults;

      return results;
    }

    const tagsResponse = await api.searchTags(searchString, apiFieldsToFetch);

    // filter out results that are not full match
    const tagHits = tagsResponse.filter(tag => checkIfFullMatch(searchString, tag.translations, ['name']));

    let {
      articleResults,
      beefCutResults,
      cuisineResults,
      recipeAuthorResults,
      recipeResults
    } = processTagsSearchResults(tagHits);

    // if tags search returned no hits, then search beef cuts localised fields
    if (!beefCutResults.length) {
      beefCutResults = (await searchBeefCuts(searchString, retailerId))
        .filter(beefCut => checkIfFullMatch(searchString, beefCut.translations, ['name', 'description']))
    }

    // if tags search returned no hits, then search article localised fields
    if (!articleResults.length) {
      articleResults = (await searchArticles(searchString, retailerId))
        .filter(article => checkIfFullMatch(searchString, article.translations, ['title', 'summary']))
        ?.map(article => {
          const translatedArticle = getTranslation(article, { key: 'languages_code', code: language.code });

          return {
            description: translatedArticle.summary,
            handleClick: handleResultClick,
            image: article.image ?
              `${process.env.REACT_APP_API_URL}/assets/${article.image}?key=system-small-cover` :
              null,
            subtitle: t('article'),
            title: translatedArticle.title,
            url: `/article/${article.id}`
          }
        });
    }

    // if tags search returned no hits, then search cuisine localised fields
    if (!cuisineResults.length) {
      cuisineResults = (await searchCollections(searchString))
        .filter(collection => checkIfFullMatch(searchString, collection.translations, ['title', 'content']))
        ?.map(collection => {
          const translatedCollection = getTranslation(collection, { key: 'languages_code', code: language.code });

          return {
            description: translatedCollection.content,
            handleClick: handleResultClick,
            image: translatedCollection.image ?
              `${process.env.REACT_APP_API_URL}/assets/${translatedCollection.image}?key=system-small-cover` :
              null,
            subtitle: ['CUISINE', 'CUISINES'].includes(collection.type) ? t('cuisine') : t('collection'),
            title: translatedCollection.title,
            url: `/collections/${collection.id}`
          }
        });
    }

    // if tags search returned no hits, then search author fields
    if (!recipeAuthorResults.length) {
      recipeAuthorResults = (await searchAuthors(searchString))
        .filter(author => checkIfFullMatch(searchString, [author], ['first_name', 'last_name', 'biography']))
        ?.map(author => ({
          description: getTranslation(author, { key: 'languages_code', code: language.code })?.biography,
          handleClick: handleResultClick,
          image: author.image ?
            `${process.env.REACT_APP_API_URL}/assets/${author.image}?key=system-small-cover` :
            null,
          subtitle: t('author'),
          title: `${author.first_name} ${author.last_name}`,
          url: `/author/${author.id}`
        }))
    }

    // if tags search returned no hits, then search recipes
    if (!recipeResults.length) {
      recipeResults = (await searchRecipes(searchString, retailerId))
        .filter(recipe => checkIfFullMatch(searchString, recipe.layout_translations, ['name', 'summary']))
        ?.map(recipe => {
          const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
          const recipeImage = recipe?.imagegallery?.length ?
            `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
            null;

          return {
            description: translatedRecipe.summary,
            handleClick: handleResultClick,
            image: recipeImage,
            subtitle: t('recipe'),
            title: translatedRecipe.name,
            url: `/recipe/${recipe.id}`
          }
        });
    }

    results.articles = articleResults;
    results.beefCuts = beefCutResults;
    results.cuisines = cuisineResults;
    results.recipeAuthors = recipeAuthorResults;
    results.recipes = recipeResults;

    return results;
  }

  useEffect(() => {
    if (filterParams?.length || searchParam) {
      triggerSearch();
    } else {
      handleSearchCleared()
    }
  }, []);

  return (
    <Box>
      {
        loading &&
        <Loader compact />
      }

      {
        !loading && noResultsFound &&
        <NoResultsFound />
      }
      
      <ListView
        items={recipes || []}
      />

      <BeefCuts
        beefCuts={beefCuts}
        handleResultClick={handleResultClick}
      />

      <ListView
        items={cuisines || []}
      />

      <ListView
        items={articles || []}
      />

      <ListView
        items={recipeAuthors || []}
      />
    </Box>
  )
}

Search.propType =  {
  handleSearchTriggered: func,
  handleSearchCleared: func,
  handleSearchComplete: func,
  storageKey: string,
  compact: bool,
};

export default Search