import { Box, Grid, Stack } from "@mui/material"
import LazyLoad from "react-lazyload"
7
import PropTypes, { arrayOf, shape, string } from 'prop-types';
import ListItem from "../ListItem";
import { LIST_ITEM } from "../../utils/prop-types";

function ListView({ items}) {
    return (
        <Box>
            {
                items?.map(item => (
                    <ListItem
                        key={item.url}
                        subtitle={item.subtitle}
                        title={item.title}
                        description={item.description}
                        image={item.image}
                        to={item.to}
                        url={item.url}
                        variant={item.variant}
                        handleClick={item.handleClick}
                    />
                ))
            }
        </Box>
    )
}

ListView.propTypes = {
    items: arrayOf(
        shape(LIST_ITEM)
    ).isRequired,
}

export default ListView;