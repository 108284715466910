import { forwardRef, useContext, useEffect, useState } from "react";

import { StarRounded } from "@mui/icons-material";

import { Box, Slide, Stack, Typography } from "@mui/material";

import styles from './index.module.scss';

import { useFetchPoints } from "../../../hooks/useFetchPoints";
import { AwardsContext } from "../../../context/AwardsContext";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            unmountOnExit
            {...props}
        />
    );
});

const DialogAchievements = () => {
    const { totalPoints, trigger: triggerFetchPoints } = useFetchPoints();

    const [starIconClasses, setStarIconClasses] = useState(`${styles.unanimated}`);
    const [pointsClasses, setPointsClasses] = useState(`${styles.hidePoints}`);
    const [pointsContainerClasses, setPointsContainerClasses] = useState(``);

    const { showPoints, hidePointsAnimation, setModalToOpen } = useContext(AwardsContext);

    const animateAwardPointsIn = () => {
        setStarIconClasses(`${styles.animated} animating`);
        animateAwardPointsInButton();
    };

    const animateAwardPointsOut = () => {
        setStarIconClasses(`${styles.unanimated}`);
    };

    const animateAwardPointsInButton = () => {
        setPointsClasses(`${styles.showPoints}`);
        setPointsContainerClasses(`${styles.pointsContainer}`);
    };

    const animateAwardPointsOutButton = () => {
        setPointsClasses(`${styles.hidePoints}`);
        setPointsContainerClasses(``);
    };

    useEffect(() => {
        let timeout;

        if (showPoints) {
            animateAwardPointsIn();
            animateAwardPointsInButton();
            triggerFetchPoints();

            timeout = setTimeout(() => {
                animateAwardPointsOutButton();
                animateAwardPointsOut();
                hidePointsAnimation();
            }, 3000);
        }

        return () => clearTimeout(timeout)
    }, [showPoints]);

    return (
        <Box
            className='no-print'
        >
            <a
                href="#"
                onClick={(event) => {
                    event.preventDefault();
                    setModalToOpen('my-achievements');
                }}
            >
                <Stack
                    className={pointsContainerClasses}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        color: '#000',
                        borderRadius: '2rem',
                    }}
                    onMouseEnter={() => {
                        animateAwardPointsIn();
                        animateAwardPointsInButton();
                    }}
                    onMouseLeave={() => {
                        animateAwardPointsOut();
                        animateAwardPointsOutButton();
                    }}
                >
                    <Box
                        className={starIconClasses}
                        sx={{
                            height: '2rem',
                            width: '2rem',
                            borderRadius: '50%',
                        }}
                    >
                        <StarRounded
                            className={showPoints ? 'spin-slow' : ''}
                            sx={{
                                ml: 'auto',
                                fontSize: 32,
                            }}
                        />
                    </Box>
                    <Box
                        className={pointsClasses}
                        sx={{}}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                textAlign: 'center',
                                pb: 0.25,
                                color: '#f7ac16',
                            }}
                        >
                            {totalPoints}
                        </Typography>
                    </Box>
                </Stack>
            </a>
        </Box>
    )
}

export default DialogAchievements;