import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import BackgroundImage from '../../shared/img/video-thumbnail.jpg';
import RetailerContext from '../../context';
import { getTranslation } from '../../utils/translate';
import { useSelector } from 'react-redux';

import './index.scss';

const CollectionCard = ({ collection = {} }) => {
    const language = useSelector(state => state.language);
    const location = useLocation();

    const { retailer } = useContext(RetailerContext);

    console.log('collection: ', collection);

    const translatedCollection = getTranslation(collection, { key: 'languages_code', code: language.code });

    const placeholderImage = retailer.placeholder_image ?
    `${process.env.REACT_APP_API_URL}/assets/${retailer.placeholder_image}?key=tile-background-image-wide`
    : BackgroundImage;

    const collectionImage = translatedCollection.image ?
        `${process.env.REACT_APP_API_URL}/assets/${translatedCollection.image}?key=tile-background-image-wide` :
        placeholderImage;

    return (
        <Link
            to={{
                pathname: `/collections/${collection.id}`,
                state: {
                    collection,
                    from: `${location.pathname}${location.search}`
                }
            }}
            style={{ textDecoration: 'none' }}
            className="w-100"
        >
            <Stack
                direction="column"
                justifyContent="flex-end"
                textAlign="center"
                sx={{
                    py: {
                        xs: 2,
                        md: 3,
                        lg: 3,
                    },
                    px: {
                        xs: 2,
                        md: 3,
                        lg: 3,
                    },
                    color: '#ffffff',
                    border: '1px solid #000',
                    borderRadius: '20px',
                    height: '200px',
                    background: `linear-gradient(to bottom, RGBA(0, 0, 0, 0.55), RGBA(0, 0, 0, 0) 57%, RGBA(0, 0, 0, 0.77)), url(${collectionImage}) no-repeat center center`,
                    backgroundBlendMode: "multiply",
                    backgroundSize: 'cover',
                    position: 'relative',
                    boxShadow: '-2px 10px 20px rgba(0,0,0,0.6) inset'
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        my: 0,
                        color: '#ffffff',
                        fontSize: '32px',
                        fontWeight: '500'
                    }}
                >
                    { getTranslation(collection, { key: 'languages_code', code: language.code }).title }
                </Typography>
            </Stack>
        </Link>
    )
};

export default CollectionCard;