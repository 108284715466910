import { useSelector } from "react-redux";

import { Box, Grid, Stack } from "@mui/material";

import { arrayOf, object, shape, string } from "prop-types";
import { useRetailerSettings } from "../../../hooks/useRetailerSettings";
import ListItem from "../../ListItem";
import { getTranslation } from "../../../utils/translate";

const Articles = ({ items = [] }) => {
    const { code: languageCode } = useSelector(state => state.language);

    const { retailerSettings } = useRetailerSettings();

    return (
        <Grid container
            spacing={2}
            sx={{
                my: 2,
            }}
        >
            {
                items
                    ?.filter(article => {
                        const retailerIds = article.retailers.map(retailer => retailer.retailer_id)
                        return retailerIds.includes(retailerSettings.id)
                    })
                    ?.map(article => (
                        <Grid item
                            key={article.id}
                            sx={{
                                mb: 2,
                            }}
                            xs={12}
                        >
                            <ListItem
                                url={`/article/${article.id}`}
                                variant='column'
                                image={`${process.env.REACT_APP_API_URL}/assets/${article.image}?key=page-hero-image-contain`}
                                subtitle={getTranslation(article.category, { key: 'languages_code', code: languageCode }).name}
                                title={getTranslation(article, { key: 'languages_code', code: languageCode }).title}
                                description={getTranslation(article, { key: 'languages_code', code: languageCode }).summary}
                                footer={(
                                    <Stack
                                        sx={{
                                            mt: 2,
                                        }}
                                    >
                                        {
                                            article?.authors.map(({ recipe_author_id: author }) => (
                                                <ListItem
                                                    variant='compact'
                                                    key={author.id}
                                                    url={`/author/${author.id}`}
                                                    image={`${process.env.REACT_APP_API_URL}/assets/${author.image}?key=page-hero-image-contain`}
                                                    title={getAuthorDisplayName(author)}
                                                    subtitle={author.credentials}
                                                />
                                            ))
                                        }
                                    </Stack>
                                )}
                            />
                        </Grid>
                    ))
            }
        </Grid>
    )
}

Articles.propTypes = {
    items: arrayOf(shape({
        id: string.isRequired,
        image: string.isRequired,
        category: object,
        translations: arrayOf(object),
        authors: arrayOf(object),
        retailers: arrayOf(object),
    })),
}

export default Articles;