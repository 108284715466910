import { useEffect, useState, Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import API from '../../api';

import './index.scss';

import { updateLanguage } from '../../actions/Language';
import CheckListItem from '../RecipeAccordion/CheckListItem';
import RetailerContext from '../../context';
import { DialogContent } from '@mui/material';
import { pushToGoogleAnalytics } from '../../utils/google-analytics';


function LanguageDialog(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { retailer } = useContext(RetailerContext);

  const selectedLanguage = useSelector(state => state.language);

  const { onClose, selectedValue, open } = props;

  const [languages, setLanguages] = useState([]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (language) => {
    onClose(language);

    pushToGoogleAnalytics({
      event: 'languageSelection',
      text: language.name,
    });

    dispatch(updateLanguage(language));
    i18n.changeLanguage(language.code.split('-')[0]);
  };

  useEffect(async () => {
    const languages = [
      {
        "code": "en-US",
        "name": "English"
      },
      {
        "code": "fr-FR",
        "name": "French"
      }
    ];
    
    setLanguages(languages.map(language => {
      language.name = t(`language_${language.name}`);
      return language;
    }));
  }, [selectedLanguage]);

  return (
    <Dialog
      className='language-switcher page-header'
      onClose={handleClose}
      open={open}
    >
      <DialogTitle
          sx={{
              boxShadow: '0 16px 15px 0 rgba(0, 0, 0, 0.18)',
              py: 3,
          }}
          component={'div'}
      >
          <Typography
            className='title'
              variant="h1"
              sx={{
                  color: retailer.brand_color,
                  fontSize: '32px',
                  fontWeight: 'bolder',
                  textTransform: 'uppercase',
                  mr: 1 
              }}
          >
            { t('language_chooseYourLanguage') }
          </Typography>
      </DialogTitle>
      <DialogContent sx={{ pt: 2, px: 0 }}>
        <Container
          sx={{
            mt: 4
          }}
        >
          <Trans>
            {t('language_disclaimer')}
          </Trans>
        </Container>
        <List sx={{ mt: 2 }}>
          {languages.map((language) => (
            <Fragment key={language.code}>
              <Container key={language.code} onClick={() => handleListItemClick(language)}>
                <CheckListItem
                  checked={language.code === selectedLanguage.code}
                  item={language}
                />
              </Container>
            </Fragment>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object,
};

export default function LanguageSwitcher() {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const language = useSelector(state => state.language);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          background: 'transparent',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: '28px',
            width: '28px',
            cursor: 'pointer'
          }}
          onClick={handleClickOpen}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            className='callout'
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              background: '#fff',
              borderRadius: '0'
            }}
          >
            <Typography
              sx={{
                color: 'black',
                fontSize: 16,
                textTransform: 'uppercase',
              }}
            >
              { language.code.substring(0, 2) }
            </Typography>
          </Stack>
        </Box>
        <LanguageDialog

          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Stack>
  );
}
