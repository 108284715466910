import { useState, Fragment, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Box, DialogContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import CheckListItem from '../RecipeAccordion/CheckListItem';
import RetailerContext from '../../context';

import updateProvince from '../../actions/Province';

import notify from '../Notification/helper';
import api from '../../api';
import { getTranslation } from '../../utils/translate';

import './index.scss';

function ProvinceDialog(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { retailer } = useContext(RetailerContext);
  const { code: languageCode } = useSelector(state => state.language);

  const selectedProvince = useSelector(state => state.province);

  const { onClose, selectedValue, open, sites = [] } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (province) => {
    if (province.url) {
      window.location.href = province.url + '?redirect=0';
    }

    dispatch(updateProvince(province));
    onClose(province);
  };

  const isChecked = (site) => {
    // if current url matches selected site
    if (site?.url?.includes(window.location.hostname)) {
      return true;
    }

    return false;
  }

  return (
    <Dialog
      className='province-switcher'
      onClose={handleClose}
      open={open}
    >
      <DialogTitle
          sx={{
              boxShadow: '0 16px 15px 0 rgba(0, 0, 0, 0.18)',
              py: 3,
          }}
          component={'div'}
      >
          <Typography
            className='title'
              variant="h1"
              sx={{
                  color: retailer.brand_color,
                  fontSize: '32px',
                  fontWeight: 'bolder',
                  textTransform: 'uppercase',
                  mr: 1 
              }}
          >
          {t('province_chooseYourProvince') }
          </Typography>
      </DialogTitle>
      <DialogContent sx={{ pt: 2, px: 0 }}>
        <Container
          sx={{
            mt: 4
          }}
        >
          <Trans>
            {t('province_chooseYourProvinceMessage')}
          </Trans>
        </Container>
        <List sx={{ mt: 2 }}>
          {sites.map((province) => (
            <Fragment key={province.name}>
              <Container
                onClick={() => handleListItemClick(province)}
              >
                <CheckListItem
                  checked={isChecked(province)}
                  item={{
                    name: getTranslation(province, { key: 'languages_code', code: languageCode })?.label,
                  }}
                />
              </Container>
            </Fragment>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object,
};

export default function SiteSwitcher() {
  const { t } = useTranslation();
  const language = useSelector(state => state.language);

  const { retailer } = useContext(RetailerContext);

  const [open, setOpen] = useState(false);
  const [sites, setSites] = useState([]);

  const [selectedValue, setSelectedValue] = useState({});


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect( async () => {
    try {
      const redirectionSites = await api.getListRedirectSites({
        sort: 'sort',
        filter: {
          '_and': ([
            {
              retailer: {
                id: {
                  '_eq': retailer.id
                }
              },
            },
            {
              status: {
                '_eq': 'published'
              }
            },
          ])
        }
      });

      setSites(
        redirectionSites.map(site => (
          {
            name: getTranslation(site, { key: 'languages_code', code: language.code })?.label,
            longName: getTranslation(site, { key: 'languages_code', code: language.code })?.label,
            url: site.url,
            translations: site.translations,
          }
        ))
      );
    } catch(error) {
      error?.map((err) => notify('error', err.message));
    }
  }, [])

  useEffect(() => {
    setSelectedValue(sites.find(
      site => site?.url?.includes(window.location.hostname)
    ) || sites[0]);
  }, [sites])

  return (
      <Stack
        className='province-switcher'
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          background: 'transparent',
          width: '100%'
        }}
      >
      <Box
        sx={{
          position: 'relative',
          height: '28px',
          width: '148px',
          cursor: 'pointer'
        }}
        onClick={handleClickOpen}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          className='callout'
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: '#fff',
            borderRadius: '0'
          }}
        >
          <Typography
            sx={{
              color: 'black',
              fontSize: 16,
              textTransform: 'uppercase',
            }}
          >
            {getTranslation(selectedValue, { key: 'languages_code', code: language.code })?.label}
          </Typography>
        </Stack>
      </Box>
        <ProvinceDialog
          selectedValue={selectedValue}
          open={open}
          sites={sites}
          onClose={handleClose}
        />
      </Stack>
  );
}
