import React, { useState, useContext, useEffect } from 'react';

import RetailerContext from '../../context';

import CanadaBeefLogo from '../../shared/svg/CanadaBeefLogo.svg';

import { Box } from '@mui/material';

import './Logo.scss';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Logo = ({ logoOverrideId, style = {} }) => {
  const { retailer } = useContext(RetailerContext);
  const province = useSelector(state => state.province);

  const [logo, setLogo] = useState(CanadaBeefLogo);

  useEffect(() => {
    // set retailer logo if it exists
    if (retailer.logo) {
      setLogo(`${process.env.REACT_APP_API_URL}/assets/${retailer.logo.id}?key=system-medium-contain`)
    }

    if (logoOverrideId) {
      setLogo(`${process.env.REACT_APP_API_URL}/assets/${logoOverrideId}?key=system-medium-contain`)
    }
  }, [retailer, logoOverrideId])

  return (
    <Link
      to="/"
    >
      <Box
        className="logo_info"
        sx={{}}
      >
        <img
          src={logo}
          alt="img"
          className="logo_img"
          style={{
            zIndex: 1,
            position: "relative",
            ...style
          }}
        />
      </Box>
    </Link>
  );
};

Logo.propTypes = {
  logoOverrideId: string,
};

export default Logo;
