import api from "../api";

export const fetchShortCode = async () => {
    let shortCode = localStorage.getItem('short_code');
    
    // check if short code is valid
    if (shortCode) {
        try {
            let checkCode = await api.getShortCodes(shortCode);
            console.log(checkCode);
        } catch(error){
            console.log('short code error', error);
            shortCode = false;
        }
    }

    // fetch short code if it doesn't exist
    if (!shortCode) {
        let maxTries = 5;

        for (let i = 0; i < maxTries; i++) {
            try {
                shortCode = await api.getShortCode();
                localStorage.setItem('short_code', shortCode);
                break;
            } catch (error) {
                console.log('short code error', error);
            }
        }
    }

    return shortCode;
};