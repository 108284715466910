import { any, object, oneOf, string } from "prop-types";

export const LIST_ITEM = {
    variant: oneOf(['default', 'column', 'compact', 'row', 'minimal']),
    description: string,
    footer: any,
    image: string,
    subtitle: string,
    title: string.isRequired,
    to: object,
    url: string.isRequired,
    mode: oneOf(['LIGHT', 'DARK'])
}