import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { t } from 'i18next';
import useSWR from 'swr';

import { Container, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';

import { fetcher } from '../../utils/fetcher';
import { getTranslation } from '../../utils/translate';

import PageHeader from '../../components/PageHeader';
import Loader from '../../components/Loader';

import RetailerContext from '../../context';
import NoResultsFound from '../../components/NoResultsFound';
import { useGetShortCode } from '../../hooks/useGetShortCode';
import ListView from '../../components/ListView';

function Favorites() {
  const { retailer } = useContext(RetailerContext);
  const shortCode = useGetShortCode();
  const language = useSelector(state => state.language);

  let items = [];
  let recipes = [];
  let beefCuts = [];

  const apiFieldsToFetch = [
    'icon',
    'id',
    'favorites.collection',
    'favorites.id',
    'favorites.item.*',
    'favorites.item.beef_product_overrides.id',
    'favorites.item.beef_product_overrides.retailer',
    'favorites.item.beef_product_overrides.status',
    'favorites.item.beef_product_overrides.translations.*',
    'favorites.item.category.status',
    'favorites.item.category.translations.*',
    'favorites.item.retailers.retailer_id',
    'favorites.item.retailers.sort',
    'favorites.item.favorites.*',
    'favorites.item.imagegallery.*',
    'favorites.item.layout_translations.*',
    'favorites.item.translations.biography',
    'favorites.item.translations.content',
    'favorites.item.translations.external',
    'favorites.item.translations.image',
    'favorites.item.translations.label',
    'favorites.item.translations.languages_code',
    'favorites.item.translations.languages_id',
    'favorites.item.translations.name',
    'favorites.item.translations.title',
    'favorites.item.translations.url',
    'sort',
    'translations.*',
    'type',
  ];

  const { data, isLoading } = useSWR({ url: `short_codes/${shortCode}`, fields: apiFieldsToFetch }, fetcher);

  if (data) {
    recipes = data.favorites
    ?.filter(({ collection }) => collection === 'recipe')
    ?.map(({ item: recipe }) => {
      const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
      const recipeImage = recipe?.imagegallery?.length ?
        `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
        null;

      return {
        description: translatedRecipe.summary,
        handleClick: () => {},
        image: recipeImage,
        subtitle: t('recipe'),
        title: translatedRecipe.name,
        url: `/recipe/${recipe.id}`
      }
    });
    
    beefCuts = data.favorites
    ?.filter(({ collection }) => collection === 'beef_product')
    ?.map(({ item }) => {
      const translatedItem = getTranslation(item, { key: 'languages_id', code: language.code });
      const image = `${process.env.REACT_APP_API_URL}/assets/${item.image}?key=system-small-cover`;

      return {
        description: translatedItem.description || translatedItem.specifications || '',
        handleClick: () => {},
        image: image,
        subtitle: t('beefCut'),
        title: translatedItem.name,
        url: `/recipe-list?beef_cut=${item.id}`,
        to: {
          pathname: `/recipe-list`,
          search: `?beef_cut=${item.id}`,
          state: {
            beefProduct: item
          }
        }
      }
    });

    items = [...recipes, ...beefCuts];
  }

  return (
    <Box
      className={''}
    >
      <Container maxWidth disableGutters
        sx={{
          p: 0
        }}
      >
        <Box>
          <PageHeader
            item={{ 
              name: t('favorites'),
            }}
            useAsLink={false}
          />
        </Box>
      </Container>

      <Container
        sx={{
          my: 3,
          minHeight: '200px',
        }}
      >
        <ListView
          items={items}
        />

        {
          isLoading &&
          <Loader />
        }

        {
          !isLoading && !items.length &&
          <NoResultsFound
            message={'No favorites found'}
          />
        }
      </Container>
    </Box>
  )
}

export default Favorites