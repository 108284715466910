import { arrayOf, oneOf, shape, string } from 'prop-types';

import { Box, Grid, Typography } from '@mui/material';

import styles from './index.module.scss';
import TextBlock from '../TextBlock';

function Videos({ videos, title, content, mode='light' }) {
  if (!videos.length) return null;

  const background = mode === 'light' ? '#fff' : '#000';
  const color = mode === 'light' ? '#000' : '#fff';
  const border = mode === 'light' ? '' : '1px solid #fff';

  return (
    <Box
      sx={{
        background,
        color,
        px: 2,
        py: 5,
      }}
    >
      <Box>
        <TextBlock
          title={title}
          content={content}
        />
      </Box>

      <Grid container
        spacing={2}
      >
        {
          videos.map(item => (
            <Grid item
              xs={12}
              md={6}
              lg={videos.length > 1 ? 6 : 12}
              key={item.id}
            >
              <Box
                className={styles.video}
                sx={{
                  width: "100%",
                  background: "#000",
                  alignContent: "center",
                  border,
                }}
              >
                <iframe
                  src={item.sourceUrl}
                  className="video-list_img"
                  title="Recipe Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

Videos.propTypes = {
  content: string,
  title: string,
  videos: arrayOf(
    shape({
      id: string.isRequired,
      sourceUrl: string.isRequired,
      videoType: oneOf(['vimeo', 'youtube']),
    })
  ),
  mode: oneOf(['light', 'dark']),
}

export default Videos