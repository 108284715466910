import { Box, Grid } from "@mui/material";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import CollectionCard from "../CollectionCard";

function Collections({ collections }) {
    const language = useSelector(state => state.language);

    const filteredCollections = collections
        .filter(collection => collection.status === 'published');

    return (
        <Grid container 
            spacing={2}
            sx={{
                pt: 2
            }}
        >
            {
                filteredCollections?.map(collection => (
                    <Grid
                        item
                        key={collection.id}
                        xs={12}
                        md={6}
                    >
                        <LazyLoad
                            height={330}
                            once={true}
                        >
                            <CollectionCard collection={collection} />
                        </LazyLoad>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default Collections