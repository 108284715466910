import { Box, Grid } from "@mui/material"
import LazyLoad from "react-lazyload"
import RecipeTile from "../../RecipeTile"

function Recipes({ recipes }) {
  return (
    <Grid container 
        spacing={{
            xs: 0,
        }}
    >
        {
            recipes?.map(recipe => (
                <Grid
                    item
                    key={recipe.id}
                    xs={12}
                    md={6}
                    lg={6}
                >
                    <LazyLoad
                        height={330}
                        once={true}
                    >
                        <RecipeTile
                            recipe={recipe}
                        />
                    </LazyLoad>
                </Grid>
            ))
        }
    </Grid>
  )
}

export default Recipes