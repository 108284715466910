import userSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { fetcher } from '../utils/fetcher';
import { useContext } from 'react';
import RetailerContext from '../context';
import { useGetShortCode } from './useGetShortCode';

export const useFetchAchievements = (filter) => {
    const shortCode = useGetShortCode();

    const fields = [
        '*',
        'translations.*',
        'points.*',
        'contests.*'
    ];

    const deep = {
        points: {
            _filter: {
                short_code: {
                    _eq: shortCode
                }
            }
        },
    };

    const { data: defaultData, error, isLoading } = userSWR({
        url: 'achievements',
        deep,
        fields,
        filter,
        sort: 'contests.sort'
    }, fetcher);

    const { trigger, data } = useSWRMutation({
        url: 'achievements',
        deep,
        fields,
        filter,
        sort: 'contests.sort'
    }, fetcher)

    const achievements = data ? data?.map(achievement => {
        return {
            ...achievement,
            points: achievement.points.reduce((accumulator, current) => {
                return accumulator + current.points;
            }, 0),
        }
    }) : defaultData?.map(achievement => {
        return {
            ...achievement,
            points: achievement.points.reduce((accumulator, current) => {
                return accumulator + current.points;
            }, 0),
        }
    });

    return {
        data: achievements,
        trigger,
        error,
        isLoading,
    };
};