import React from 'react';
import { Stack, SvgIcon } from '@mui/material';

function SocialMediaIcons({ shareMessage, shareImageUrl = '', sx = {} }) {
    const shareOnFacebook = (e) => {
        e.preventDefault();

        FB.ui({
            method: 'share',
            href: `${window.location.origin}?sharingMessage=${shareMessage}&shareImageUrl=${shareImageUrl}`,
        }, function (response) {
            console.log('response', response);
        });
    };

    return (
        <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            sx={{
                textDecoration: 'none',
            }}
        >
            <a
                target='_blank'
                href='https://www.facebook.com/'
                onClick={(event) => { shareOnFacebook(event) }}
            >
                <SvgIcon
                    sx={{
                        fontSize: '1.1rem',
                        color: '#000',
                        ...sx,
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 80v352c0 26.5-21.5 48-48 48h-85.3V302.8h60.6l8.7-67.6h-69.3V192c0-19.6 5.4-32.9 33.5-32.9H384V98.7c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9H184v67.6h60.9V480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z" /></svg>
                </SvgIcon>
            </a>

            <a
                target='_blank'
                className="twitter-share-button"
                href={`https://twitter.com/intent/tweet?text=${shareMessage}&url=${window.location.origin}`}
            >
                <SvgIcon
                    sx={{
                        fontSize: '1.3rem',
                        color: '#000',
                        ...sx,
                    }}
                >
                    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                    </svg>
                </SvgIcon>
            </a>
        </Stack>
    )
}

export default SocialMediaIcons