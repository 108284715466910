import { Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { t } from 'i18next';

import { Box } from '@mui/system';

import Wysiwyg from '../../components/Wysiwyg';
import Recipes from '../../components/Collection/Recipes';
import AccordionSection from '../../components/AccordionSection';
import BeefCuts from '../../components/Collection/BeefCuts';

import { getAuthorDisplayName } from '../../utils/author';

import './index.scss';
import { getTranslation } from '../../utils/translate';

function Cuisine(props) {
  const { recipes, recipeAuthors, beefCuts, title, description, imageUrl } = props;
  const { code: languageCode } = useSelector(state => state.language);

  const filteredRecipeAuthors = recipeAuthors
    .filter(individual => individual.status === 'published')
    .map(individual => ({
      title: getAuthorDisplayName(individual),
      description: getTranslation(individual, { key: 'languages_code', code: languageCode })?.biography,
      image: individual.image ?
        `${process.env.REACT_APP_API_URL}/assets/${individual.image}?key=page-hero-image-contain` :
        null,
      url: `/author/${individual.id}`
    }))

  return (
    <Box
      className={'cuisine'}
    >
      {!!imageUrl &&
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            className='collection_img_container'
            item
            sm={12}
            sx={{
              my: 3,
            }}
          >
            <img
              className="collection_img"
              src={imageUrl}
              alt={title}
            />
          </Grid>
        </Stack>
      }

      <Typography
        sx={{
          textAlign: 'justify'
        }}
        component="div"
      >
        <Wysiwyg>
          {description}
        </Wysiwyg>
      </Typography>

      {
        !!beefCuts?.length &&
        <Box
          sx={{
            mt: 3,
            mb: 5,
          }}
        >
          <BeefCuts
            beefCuts={beefCuts}
          />
        </Box>
      }

      {
        !!filteredRecipeAuthors?.length &&
        <AccordionSection
          title={t('chef')}
          expanded={true}
          sx={{
            mt: 3
          }}
        >
          <Box>
            <Grid container
              spacing={{
                xs: 0,
              }}
              sx={{
                pt: 2
              }}
            >
              {
                filteredRecipeAuthors?.map(individual => (
                  <Grid
                    item
                    key={individual.url}
                    xs={12}
                  >
                    <Link
                      to={individual.url}
                    >
                      <Stack
                        once={true}
                      >
                        <Stack
                          direction="column"
                          sx={{
                            py: {
                              xs: 2,
                              md: 3,
                              lg: 3,
                            },
                            color: '#ffffff',
                          }}
                        >
                          <img
                            className='person-image'
                            src={individual.image}
                            alt={individual.title}
                          />

                          <Typography
                            sx={{
                              mt: 1,
                              color: '#000',
                              fontWeight: 'bold',
                            }}
                            variant='h5'
                          >
                            {individual.title}
                          </Typography>

                          <Typography
                            sx={{
                              mb: 1,
                              color: '#000',
                              fontSize: '16px',
                              textAlign: 'justify'
                            }}
                            noWrap={false}
                          >
                            <Wysiwyg>
                              {individual.description}
                            </Wysiwyg>
                          </Typography>
                        </Stack>
                      </Stack>
                    </Link>
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </AccordionSection>
      }

      {
        !!recipes?.length && 
        <AccordionSection
          title={t('recipes')}
          expanded={true}
          sx={{
            mt: 3
          }}
        >
          <Recipes
            recipes={recipes}
          />
        </AccordionSection>
      }
    </Box>
  )
}

Cuisine.propTypes = {
  beefCuts: PropTypes.array,
  imageUrl: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  recipeAuthors: PropTypes.array,
  recipes: PropTypes.array,
}

export default Cuisine;