import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Divider, Stack, Typography } from '@mui/material';

import BackgroundImage from '../../shared/img/video-thumbnail.jpg';
import RetailerContext from '../../context';
import { useSelector } from 'react-redux';

import styles from './index.module.scss';

import { any, bool, object, oneOf, string } from 'prop-types';
import { Box } from '@mui/system';
import Wysiwyg from '../Wysiwyg';

const ListItem = ({ url, to, image, subtitle, title, description, mode='LIGHT', variant = 'default', footer = null, ...other }) => {
    const { retailer } = useContext(RetailerContext);

    const placeholderImage = retailer.placeholder_image ?
    `${process.env.REACT_APP_API_URL}/assets/${retailer.placeholder_image}?key=system-large-cover`
    : BackgroundImage;
    
    let fontColour = '#000';

    if (mode === 'DARK') {
        fontColour = '#fff';
    }

    const defaultListItem = (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className={`${styles.default_size} ${styles.default_shadow}`}
        >
            <img
                className={styles.list_item_image}
                src={image || placeholderImage}
            />

            <Box
                sx={{
                    width: 'calc(100% - 100px)',
                    overflow: 'hidden'
                }}
            >
                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '0.6rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {subtitle}
                </Typography>

                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '1.1rem',
                        fontWeight: 'bold'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {title}
                </Typography>

                <Typography
                    sx={{
                        color: '#3c3a3a',
                        fontSize: '0.9rem',
                        textAlign: 'justify'
                    }}
                    noWrap={true}
                    component={'div'}   
                    className={styles.wysiwyg}
                >
                    <Wysiwyg>
                        {description}
                    </Wysiwyg>
                </Typography>
            </Box>
        </Stack>
    );

    const compactListItem = (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className={`${styles.compact} ${styles.compact}`}
        >
            <img
                className={`${styles.list_item_image} ${styles.compact_image}`}
                src={image || placeholderImage}
            />

            <Box
                sx={{
                    width: 'calc(100% - 100px)',
                    overflow: 'hidden'
                }}
            >
                <Typography
                    sx={{
                        color: '#3c3a3a',
                        fontSize: '0.6rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {subtitle}
                </Typography>

                <Typography
                    sx={{
                        color: fontColour,
                        fontSize: '1.1rem',
                        fontWeight: 'bold'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {title}
                </Typography>
            </Box>
        </Stack>
    );

    const minimalListItem = (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className={`${styles.compact} ${styles.compact}`}
        >
            <Box
                sx={{
                    width: 'calc(100% - 100px)',
                    overflow: 'hidden'
                }}
            >
                <Typography
                    sx={{
                        color: fontColour,
                        fontSize: '0.6rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {subtitle}
                </Typography>

                <Typography
                    sx={{
                        color: fontColour,
                        fontSize: '1.1rem',
                        fontWeight: 'bold'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {title}
                </Typography>
            </Box>
        </Stack>
    );

    const rowListItem = (
        <Stack
            direction="column"
            alignItems="center"
            className={`${styles.row_size}`}
        >
            <img
                className={styles.image_for_row}
                src={image || placeholderImage}
            />

            <Box
                sx={{
                    width: 'calc(100% - 1rem)',
                    overflow: 'hidden',
                    py: 2
                }}
            >
                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '0.6rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {subtitle}
                </Typography>

                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '2rem',
                        fontWeight: 'bold'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {title}
                </Typography>

                <Typography
                    className={styles.row_description}
                    sx={{
                        color: '#3c3a3a',
                        fontSize: '0.9rem',
                        textAlign: 'justify'
                    }}
                    noWrap={true}
                    component={'div'}   
                >
                    <Wysiwyg>
                        {description}
                    </Wysiwyg>
                </Typography>

                <Box>
                    {footer}
                </Box>
            </Box>
        </Stack>
    );

    const columnListItem = (
        <>
            <Stack
                direction="column"
                sx={{
                    boxShadow: '-12px -12px 20px 0 rgba(255, 255, 255, 0.2), 12px 12px 20px 0 RGBA(209, 205, 199, 0.5)',
                }}
            >
                <img
                    className={styles.image_for_row}
                    src={image || placeholderImage}
                />

                <Box
                    sx={{
                        overflow: 'hidden',
                        py: 2,
                        px: 2,
                    }}
                >
                    <Typography
                        sx={{
                            color: '#8f9297',
                            fontSize: '0.9rem',
                            fontWeight: 'bold',
                            textTransform: 'uppercase'
                        }}
                        noWrap={true}
                        component={'div'}
                    >
                        {subtitle}
                    </Typography>

                    <Typography
                        sx={{
                            color: '#000',
                            fontSize: '2rem',
                            fontWeight: 'bold'
                        }}
                        component={'div'}
                    >
                        {title}
                    </Typography>

                    <Typography
                        className={styles.row_description}
                        sx={{
                            color: '#3c3a3a',
                            fontSize: '0.9rem',
                            textAlign: 'justify'
                        }}
                        component={'div'}   
                    >
                        <Wysiwyg>
                            {description}
                        </Wysiwyg>
                    </Typography>

                    <Box>
                        {footer}
                    </Box>
                </Box>
            </Stack>
        </>
    );

    let listItemVariant = defaultListItem;

    if (variant === 'compact') {
        listItemVariant = compactListItem;
    }

    if (variant === 'row') {
        listItemVariant = rowListItem;
    }

    if (variant === 'column') {
        listItemVariant = columnListItem;
    }

    if (variant === 'minimal') {
        listItemVariant = minimalListItem;
    }

    return (
        <Link
            to={
                !!to ? to : {
                    pathname: url,
                }
            }
            style={{ textDecoration: 'none' }}
            {...other}
        >
            {listItemVariant}
        </Link>
    )
};

ListItem.propTypes = {
    variant: oneOf(['column', 'compact', 'row', 'minimal']),
    description: string,
    footer: any,
    image: string,
    subtitle: string,
    title: string.isRequired,
    to: object,
    url: string.isRequired,
    momde: oneOf(['LIGHT', 'DARK'])
}

export default ListItem;