import { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { Stack, Typography } from '@mui/material';

import RetailerContext from '../../context';
import { getTranslation } from '../../utils/translate';
import { getBeefProductOverride } from '../../utils/overrides';

// TODO refactor props in this component
const BeefProductTile = ({ item = {}, asscociateNewBarcodeToProduct = () => {} }) => {
    const retailerContext = useContext(RetailerContext);
    const language = useSelector(state => state.language);

    function useQuery() {
        const { search } = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const barcode = query.get('barcode') || null;

    // if hiearchy is not defined then redirect to recipe list page
    let pathname = `/recipe-list`;
    let searchParams = `?beef_cut=${item.id}`;

    // else redirect to beef cut page
    if (item.hierarchy === 'PRIMAL') {
        pathname = `/beef/primal/${item.id}`;
        searchParams = '';
    }

    if (item.hierarchy === 'SUBPRIMAL') {
        pathname = `/beef/subprimal/${item.id}`;
        searchParams = '';
    }

    item.recipes = item.recipes?.filter(recipe => {
        const subscribedRetailers = recipe.recipe_id?.retailers.map(retailer => retailer.retailer_id) || [];

        return (
            (recipe.recipe_id?.retailer === retailerContext.retailer.id) ||
            subscribedRetailers.includes(retailerContext.retailer.id)
        )
    }) || [];

    return (
        <>
            <Accordion
                disableGutters={true}
                square={true}
                sx={{ 
                    mb: 3,
                    boxShadow: 'none'
                }}
            >
                <AccordionSummary
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    sx={{
                        backgroundColor: '#EFEEEE',
                        my: 0,
                        px: 0,
                        boxShadow: '-12px -12px 20px 0 rgba(255, 255, 255, 0.2), 12px 12px 20px 0 RGBA(209, 205, 199, 0.5)',
                        '& .MuiAccordionSummary-content': {
                            my: 0
                        }
                    }}
                >
                    <Link
                        className='w-100'
                        key={item.id}
                        to={
                            item.hierarchy=="PRIMAL"||item.hierarchy=="SUBPRIMAL"?{
                                pathname: `/beef/${item.id}`,
                                state: {
                                    beefProduct: item
                                }
                            }:{
                                pathname: `/recipe-list`,
                                search: `?beef_cut=${item.id}`,
                                search: searchParams,
                                state: {
                                    from: `${location.pathname}${location.search}`
                                }
                            }
                        }
                        onClick={(event) => {
                            // if there's a barcode params then the associate that barcode with the selected beef product
                            if (barcode) {
                                event.preventDefault();
                                asscociateNewBarcodeToProduct(barcode, item)
                            }
                        }}
                    >
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                        >
                            <Typography
                                sx={{
                                    mx: 2,
                                    my: 1,
                                    textTransform: 'uppercase',
                                    color: '#262626',
                                    fontSize: '22px',
                                    fontWeight: 200
                                }}
                                component={'div'}
                            >
                                { 
                                    getTranslation(getBeefProductOverride(item, retailerContext.retailer, language), { key: 'languages_code', code: language.code }).name ||
                                    getTranslation(item, { key: 'languages_id', code: language.code }).name
                                }
                            </Typography>
                        </Stack>
                    </Link>
                </AccordionSummary>
            </Accordion>
        </>
    )
};

export default BeefProductTile;