import { 
    Box,
    Container,
    Stack,
} from '@mui/material';

import Logo from '../Logo';
import { string } from 'prop-types';

const PageLogo = ({ logoOverrideId }) => {
    return (
        <Box
            className='page-header'
            sx={{}}
        >
            <Container
                className='padding-on-mobile'
            >
                <Stack
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        mt: 8,
                        mb: 4,
                    }}
                >
                    <Logo
                        logoOverrideId={logoOverrideId}
                    />
                </Stack>
            </Container>
        </Box>
    )
};

PageLogo.propTypes = {
    logoOverrideId: string,
};

export default PageLogo;