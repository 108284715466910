export const getBeefProductOverride = (beefProduct, retailer, language) => {
    const beefProductOverride = {};
    let beefProductOverrides = beefProduct.beef_product_overrides;

    // filter out other unpublished overrides
    beefProductOverrides = beefProductOverrides?.filter(beefProductOverride => beefProductOverride?.status === 'published');

    // filter out other retailers' overrides
    beefProductOverrides = beefProductOverrides?.filter(beefProductOverride => beefProductOverride?.retailer === retailer.id);

    // filter out blank translations due to testing, errors, etc
    beefProductOverrides = beefProductOverrides?.map(beefProductOverride => {
        beefProductOverride.translations = beefProductOverride.translations.filter(translation => [language.code, 'en-US'].includes(translation.languages_code));
        return beefProductOverride;
    });

    return beefProductOverrides?.length ?
        beefProductOverrides[0] :
        beefProductOverride;
};

export const getRecipeVideoOverride = (recipe, retailerId, languageCode) => {
    let videoOverrides = recipe.video_overrides || [];

    // filter out unpublished overrides
    videoOverrides = videoOverrides?.filter(videoOverride => videoOverride.status === 'published');

    // filter out other retailers' overrides
    videoOverrides = videoOverrides?.filter(videoOverride => videoOverride.retailer === retailerId);

    // filter out blank translations due to testing, errors, etc
    videoOverrides = videoOverrides?.map(videoOverride => {
        videoOverride.translations = videoOverride.translations
            .filter(
                translation => [languageCode, 'en-US'].includes(translation.languages_code)
            );
        
        return videoOverride;
    });
    
    return videoOverrides;
}