import { forwardRef, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Dialog, DialogContent, IconButton, OutlinedInput, Slide, Stack, Typography } from "@mui/material";

import RetailerContext from "../../../context";

import Stripes from '../../../shared/img/stripes.png';
import { setShortCode as updateShortCode } from "../../../utils/gamification";
import { useGetShortCode } from "../../../hooks/useGetShortCode";
import api from "../../../api";
import notify from "../../Notification/helper";
import { Close } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            unmountOnExit
            {...props}
        />
    );
});

const RestoreProgressDialogoxBox = (props) => {
    const { t } = useTranslation(['gamification']);
    const [shortCode, setShortCode] = useState(useGetShortCode());

    const { open = false, onClose } = props;

    const { retailer } = useContext(RetailerContext);

    const [shortCodeInput, setShortCodeInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [shortCodeRestored, setShortCodeRestored] = useState(false);

    const handleClose = (reload = true) => {
        reload && window.location.reload();
        onClose();
    };

    const handleRestore = async () => {
        try {
            setLoading(true);
            await api.getShortCodes(shortCodeInput);
            updateShortCode(shortCodeInput);
            setShortCode(shortCodeInput);
            setShortCodeRestored(true);
        } catch (error) {
            error?.map((err) => {
                if (err?.extensions?.code === 'FORBIDDEN') {
                    notify('error', 'Short Code does not exist.');
                } else {
                    notify('error', err.message);
                }
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            className='page-header'
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                }
            }}
        >
            <DialogContent
                sx={{
                    overflowX: 'hidden',
                }}
            >
                <IconButton
                    onClick={() => handleClose()}
                    sx={{
                        color: '#000',
                        position: 'absolute',
                        top: '0.15rem',
                        right: '0.25rem',
                        zIndex: '1',
                    }}
                >
                    <Close fontSize="interit" />
                </IconButton>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        position: 'relative',
                        mb: 2,
                        mx: '-1.5rem',
                        px: '1.5rem',
                    }}
                >
                    <img
                        src={Stripes}
                        alt={'Stripe Art'}
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            right: 0,
                            left: 0,
                        }}
                    />

                    <Typography
                        className='title'
                        variant="h1"
                        sx={{
                            fontSize: '2rem',
                            fontWeight: 'bolder',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            py: 3,
                        }}
                    >
                        {t('restoreTitle')}
                    </Typography>
                </Box>

                { 
                    !shortCodeRestored &&
                    <Box>
                        <Box
                            sx={{
                                my: 1,
                                mb: 2
                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    textAlign: 'center',
                                    color: '#000',
                                    fontWeight: 'bold',
                                    fontSize: '2rem',
                                }}
                            >
                                {shortCode}
                            </Typography>
                        </Box>

                        <Typography
                            sx={{
                                color: '#000',
                                fontWeight: 300,
                                fontSize: '1rem',
                                textAlign: 'justify',
                                mb: 2,
                                textTransform: 'capitalize',
                            }}
                        >
                            {t('restoreMessage').toLocaleLowerCase()}
                        </Typography>

                        <Stack
                            spacing={1}
                        >
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontSize: '0.8rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                {t('shortCode')}
                                <strong>&nbsp;*</strong>
                            </Typography>
                            <OutlinedInput
                                className="contest-input-field"
                                sx={{
                                    color: '#262626 !important',
                                    height: '3rem',
                                    py: 1,
                                    width: '100%',
                                }}
                                value={shortCodeInput}
                                onChange={(e) => setShortCodeInput(e.target.value)}
                                variant="filled"
                            />

                            <Button
                                disabled={!shortCodeInput || loading}
                                sx={{
                                    borderRadius: '0',
                                    backgroundColor: '#008F01',
                                    fontFamily: retailer.button_font,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                variant="contained"
                                onClick={handleRestore}
                            >
                                {t('restore')}
                            </Button>
                        </Stack>
                    </Box>
                }

                { 
                    shortCodeRestored &&
                    <Box>
                        <Typography
                            component={'h3'}
                            sx={{
                                color: '#000',
                                fontWeight: 'bold',
                                fontSize: '1.1rem',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                            }}
                        >
                            {t('restoreMessageSuccessTitle')}
                        </Typography>

                        <Typography
                            sx={{
                                color: '#000',
                                fontWeight: 300,
                                fontSize: '1rem',
                                textAlign: 'center',
                                textTransform: 'sentence',
                            }}
                        >
                            {t('restoreMessageSuccessMessage')}
                        </Typography>

                        <Button
                            variant="outlined"
                            sx={{
                                width: '100%',
                                borderRadius: '0',
                                border: '2px solid #7c8184',
                                fontFamily: retailer.button_font,
                                color: '#7c8184',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                my: 1,
                                ':hover': {
                                    bgcolor: '#7c8184',
                                    color: '#fff',
                                },
                            }}
                            onClick={handleClose}
                            disabled={loading}
                        >
                            {t('close')}
                        </Button>
                    </Box>
                }

                <Box
                    sx={{
                        textAlign: 'center',
                        mt: 1,
                    }}
                >
                    
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export const DialogRestoreProgress = ({ defaultOpen = false, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Box
            className='no-print'
        >
            <RestoreProgressDialogoxBox
                open={defaultOpen}
                onClose={handleClose}
            />
        </Box>
    )
}