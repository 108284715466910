import React from 'react';
import Notification from './components/Notification';
import AppRouter from './routers/AppRouter';

import './styles/App.scss';
import './styles/index.scss';
import AwardsProvider from './providers/AwardsProvider';

const App = () => (
  <>
    {/* <Notification /> */}
    <AwardsProvider>
      <AppRouter />
    </AwardsProvider>
  </>
);

export default App;
