import {
  Box,
  Typography,
} from "@mui/material";

import { string } from 'prop-types';
import Wysiwyg from '../Wysiwyg';

import styles from './index.module.scss';

const TextBlock = ({title, content}) => {
  return (
    <Box>
      <Typography
        variant='h3'
      >
        <Wysiwyg>
          {title}
        </Wysiwyg>
      </Typography>

      <Typography
        variant='div'
        className={styles.content}
      >
        <Wysiwyg
          allowedTags={['img', 'table', 'td', 'th', 'thead', 'tbody', 'tr']}
        >
          {content}
        </Wysiwyg>
      </Typography>
    </Box>
  );
};

TextBlock.propTypes = {
  title: string,
  content: string,
};

export default TextBlock;
