import { useTranslation } from "react-i18next";

import { Box, Grid, Typography } from "@mui/material";

import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';

import Wysiwyg from "../../Wysiwyg";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../utils/translate";
import { useContext } from "react";
import RetailerContext from "../../../context";

export const DialogContestTitle = () => {
    const { t } = useTranslation(['gamification', 'provinces']);
    const { code: languageCode } = useSelector(state => state.language);
    const contests = useSelector(state => state.contests);
    const { retailer } = useContext(RetailerContext);

    const contest = contests?.find(item => item?.id) || null;

    let tagLine;
    let logo;

    if (contest) {
        const translatedContest = getTranslation(contest, { key: 'languages_code', code: languageCode });

        logo = `${process.env.REACT_APP_API_URL}/assets/${translatedContest?.logo}?key=contest-logo` || CanadaBeefLogo;
        tagLine = translatedContest?.tagline || t('enterContestTitle');
    }

    return (
        <Box
            sx={{}}
        >
            <Box>
                <Grid container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Grid item
                        xs={8}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: {
                                    xs: '1rem',
                                    s: '1.5rem',
                                    md: '1.5rem',
                                },
                                fontWeight: 'bolder',
                                '& strong': {
                                    color: retailer.brand_color,
                                },
                                '.wysiwyg p': {
                                    my: 0,
                                },
                                lineHeight: '1'
                            }}
                        >
                            <Wysiwyg>
                                {tagLine}
                            </Wysiwyg>
                        </Typography>
                    </Grid>
                    <Grid
                        xs={4}
                        sx={{
                            height: '4rem',
                        }}
                    >
                        <img
                            className=""
                            src={logo}
                            alt={'Contest Logo'}
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}