import { Box } from '@mui/material';
import * as React from 'react';
import '../styles/loader.scss';

const Loader = ({compact, variant}) => (
  <Box
    className="loader-container"
    sx={{
      width: compact ? 'unset !important' : '100vw',
      height: compact ? 'unset !important' : '100vh',
    }}
  >
    <Box
      className={`loader loader-${variant}`}
    />
  </Box>
);

export default Loader;
