import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../utils/translate";
import DashboardCard from "../../DashboardCard";

function MenuItems({menuItems}) {
    console.log('menuItems: ', menuItems);
    const language = useSelector(state => state.language);

    const parsedMenuItems = menuItems.map(menuItem => {
        // determine if internal link
        let url = menuItem.url;

        if (menuItem.items.length) {
            const item = menuItem.items[0];
            url = `/${item.collection}/${item.item}`
        }

        return {
            ...menuItem,
            url
        }
    })

    console.log('parsedMenuItems: ', parsedMenuItems);

    return (
        <Grid
            container
            spacing={{
                xs: 2,
                md: 4
            }}
        >
            {
                parsedMenuItems?.map((menuItem) => (
                    <Grid
                        key={menuItem.id}
                        item
                        xs={6}
                    >
                        <DashboardCard
                            item={{
                                label: getTranslation(menuItem,
                                    {
                                        key: 'languages_code',
                                        code: language.code
                                    }
                                ).label,
                                goTo: menuItem.url,
                                enlargeImg: true
                            }}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default MenuItems