import { Box } from '@mui/system';
import DOMPurify from 'dompurify';

function DescriptionHolder(props) {
  const { content='', allowedTags=[], component='div' } = props;

  const config = {
    ALLOWED_TAGS: ['a', 'br', 'p', 'b', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'strong', 'span', 'div', ...allowedTags],
  };

  return (
    <Box
      component={component}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content, config) }}
    />
  )
}

export default DescriptionHolder