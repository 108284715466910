import { createContext } from 'react';

import CanadaBeefLogo from '../shared/svg/CanadaBeefLogo.svg';

const RetailerContext = createContext({
  retailer: {
    brand_color: '#fff',
    logo: CanadaBeefLogo,
    url: 'fincent.co.zw',
  },
  setRetailer: () => {}
});

export default RetailerContext;
