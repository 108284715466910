import { Box, Container, Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RetailerContext from "../../context";
import { getBeefProductOverride } from "../../utils/overrides";
import { getTranslation } from "../../utils/translate";
import GenericCard from "../GenericCard";
import ListView from "../ListView";

function BeefCuts({ beefCuts, handleResultClick = () => {} }) {
    const language = useSelector(state => state.language);
    const { retailer } = useContext(RetailerContext);
    const { t } = useTranslation();

    // TODO consider performance impact of this
    const filteredBeefCuts = beefCuts
        .filter(beefCut => beefCut.status === 'published')
        .filter(beefCut => {
            const retailerIds = beefCut.retailers.map(retailerJunction => retailerJunction.retailer_id);
            return retailerIds.includes(retailer.id)
        })
        .sort(function (a, b) {
            const c = a.retailers.filter(retailer => retailer.retailer_id === retailer.id)?.sort;
            const d = b.retailers.filter(retailer => retailer.retailer_id === retailer.id)?.sort;

            return c - d;
        })
        .map(beefCut => {
            const beefOverride = getBeefProductOverride(beefCut, retailer, language);
            const translatedBeefOverride = getTranslation(beefOverride, { key: 'languages_code', code: language.code });
            const translatedBeefCut = getTranslation(beefCut, { key: 'languages_id', code: language.code });

            const beefCutImage = beefCut.image ?
                `${process.env.REACT_APP_API_URL}/assets/${beefCut.image}?key=system-small-cover` :
                null;

            return {
                description: translatedBeefOverride.description || translatedBeefCut.description,
                handleClick: handleResultClick,
                image: beefCutImage,
                subtitle: t('beefCut'),
                title: translatedBeefOverride.name || translatedBeefCut.name,
                url: `/recipe-list?beef_cut=${beefCut.id}`,
                to: {
                    pathname: `/recipe-list`,
                    search: `?beef_cut=${beefCut.id}`,
                    state: {
                        beefProduct: beefCut
                    }
                }
            }
        });
        
    return (
        <ListView
            items={filteredBeefCuts}
        />
    )
}

export default BeefCuts