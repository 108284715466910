import { forwardRef, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import RetailerContext from "../../../context";
import { AwardsContext } from "../../../context/AwardsContext";

import ContestArt from '../../../shared/img/Bitmap.png';

import { getTranslation } from "../../../utils/translate";

import Wysiwyg from "../../Wysiwyg";
import { DialogContestTitle } from "../DialogContestTitle";

import { useFetchAchievements } from "../../../hooks/useFetchAchievements";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const AwardDialogoxBox = ({ open, onClose }) => {
    const { t } = useTranslation(['gamification']);

    const { code: languageCode } = useSelector(state => state.language);

    const [enteredContentStatus, setEnteredContentStatus] = useState('');
    const [isThereAnotherAchievement, setIsThereAnotherAchievement] = useState('idle');

    const { retailer } = useContext(RetailerContext);
    const { achievement } = useContext(AwardsContext);

    const contests = useSelector(state => state.contests);
    const contest = contests?.find(item => item?.id) || null;

    let featuredImage;

    if (contest) {
        const translatedContest = getTranslation(contest, { key: 'languages_code', code: languageCode });

        featuredImage = `${process.env.REACT_APP_API_URL}/assets/${translatedContest?.featured_image}?key=contest-feature-image` || '';
    }

    let translatedAchievement = {};

    if (achievement) {
        translatedAchievement = getTranslation(achievement, { key: 'languages_code', code: languageCode });
    }

    const filter = {
        _and: [
            {
                status: {
                    '_eq': 'published'
                }
            },
            ...(contest ? [
                {
                    contests: {
                        contests_id: {
                            '_eq': contest.id
                        }
                    }
                }
            ] : []),
        ]
    };

    const { data } = useFetchAchievements(filter);

    let nextAchievementId = null;
    let total = 0;
    let completed = 0;

    for (let i = 0; i < data?.length; i++) {
        total += 1;

        const achievement = data[i];

        // if achievement has not been completed, then set it as next achievement
        if (((achievement.points / achievement.target) * 100) >= 100) {
            completed += 1;
        } else {
            // ensure achievements higher up in the list are completed 1st
            nextAchievementId = nextAchievementId || achievement.id
        }
    }

    const handleClose = (close = false) => {
        let modalToOpen = null;

        // if user has not entered contest, then open contest form
        if (enteredContentStatus === 'not entered') {
            modalToOpen = 'contest-info';
        }

        if (isThereAnotherAchievement === 'yes') {
            if (achievement.modalToOpen) {
                modalToOpen = achievement.modalToOpen;
            }
        } else {
            modalToOpen = 'promotion';
        }

        // if close is true, then close the modal
        if (close === true) {
            modalToOpen = 'promotion';
        }
        
        onClose(modalToOpen);
    };

    const handleOnClose = (close = false) => {
        let modalToOpen = '';

        // if user has completed all achievements, then open promotion
        if (completed === total) {
            modalToOpen = 'promotion';
        }

        // if close is true, then close the modal
        if (close === true) {
            modalToOpen = 'promotion';
        }
        
        onClose(modalToOpen);
    };

    return (
        <Dialog
            className='page-header'
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            onClose={() => handleClose(true)}
            TransitionComponent={Transition}
            scroll="paper"
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <IconButton
                onClick={() => handleClose('promotion')}
                sx={{
                    color: '#000',
                    position: 'absolute',
                    top: '0.15rem',
                    right: '0.25rem',
                    zIndex: '1',
                }}
            >
                <Close fontSize="interit" />
            </IconButton>

            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent
                sx={{}}
            >
                <Box
                    sx={{
                        textAlign: 'right',
                        fontSize: '0.6rem'
                    }}
                >
                    Entries {completed}/{total}
                </Box>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        width: '100%',
                        background: "#eeeeee",
                        mb: '0.5rem',
                    }}
                >
                    <Box
                        sx={{
                            height: {
                                xs: 'auto',
                            },
                            width: `${Math.floor((completed / total) * 100)}%`,
                            background: "#004466",
                            clear: "none",
                            zIndex:"-1",
                            position: "relative-layer",
                            textAlign: 'right',
                            color: '#ffffff',
                        }}
                    >
                        {Math.floor((completed / total) * 100)}% &nbsp;
                    </Box>
                </Box>

                <Typography
                    className='title'
                    variant="h2"
                    sx={{
                        color: '#000',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        mt: 3,
                        lineHeight: '1.25rem',
                        mb: 1,
                    }}
                >
                    {
                        !!translatedAchievement?.success_message && <Wysiwyg>{translatedAchievement?.success_message}</Wysiwyg>
                    }
                    {
                        !!!translatedAchievement?.success_message && (
                            <>
                                {completed !== total && <Wysiwyg>{t('contestGainedAnotherEntry')}</Wysiwyg>}
                                {completed === total && <Wysiwyg>{t('completedAllAchievementsContent')}</Wysiwyg>}
                            </>
                        )
                    }
                </Typography>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        width: '100%',
                    }}
                >
                    <img
                        src={featuredImage || ContestArt}
                        alt={'Contest Art'}
                        style={{
                            height: '150px',
                            width: '100%',
                            objectFit: 'cover',
                            objectPosition: 'bottom',
                        }}
                    />
                </Box>
                
                {
                    !!completed &&
                    <Button
                        variant="contained"
                        sx={{
                            borderRadius: '0',
                            backgroundColor: '#008F01',
                            fontFamily: retailer.button_font,
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            width: '100%',
                            mb: 2,
                        }}
                        onClick={handleOnClose}
                    >
                        {completed !== total && t('thirdChanceToWin')}
                        {completed === total && t('completedAllAchievements')}
                    </Button>
                }
            </DialogContent>
        </Dialog>
    )
}

const DialogAward = ({ defaultOpen = false, onClose }) => {
    const { achievement } = useContext(AwardsContext);

    const handleClose = (component) => {
        if (onClose) {
            onClose(component);
        }
    };

    return (
        <Box
            className='no-print'
        >
            <AwardDialogoxBox
                achievement={achievement}
                open={defaultOpen}
                onClose={handleClose}
                key={defaultOpen}
            />
        </Box>
    )
}

export default DialogAward;